<style lang="scss" global>
.mobile-menu-wrapper {
  #btn-close-mobile-menu{
    &.open{
      padding: 0;
      line-height: 2;
      border-color: #57b957;
      background-color: #57b957;

      &:before{
        content: 'x';
        color: #fff;
        font-size: 7px;
      }
    }
  }
}
</style>
<div class="mobile-menu-wrapper">
  <a id="btn-close-mobile-menu" href="javascript:;"></a>
  <div class="mobile-menu-content">
    <div class="menu-main-menu-container">
      <ul id="mobile_main_menu" class="mobile-main-nav">
        <li class='menu-item' class:current-menu-item={ route().current('app.home') }>
          <a href="{ route('app.home') }">Home</a>
        </li>
        <li class='menu-item' class:current-menu-item={ route().current('app.credit') }>
          <a href="{ route('app.credit') }">Business Line of Credit</a>
        </li>
        <li class='menu-item' class:current-menu-item={ route().current('app.about') }>
          <a href="{ route('app.about') }">About Us</a>
        </li>
        <li class='menu-item' class:current-menu-item={ route().current('app.careers') }>
          <a href="{ route('app.careers') }">Careers</a>
        </li>
        <li class='menu-item' class:current-menu-item={ route().current('app.contact') }>
          <a href="{ route('app.contact') }">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
</div>
