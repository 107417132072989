<section class="elementor-element elementor-element-0b6c81d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="0b6c81d" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-21d6595 elementor-column elementor-col-33 elementor-top-column" data-id="21d6595" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-f51202a elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="f51202a" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">What makes us move forward</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-f1e65f0 elementor-column elementor-col-33 elementor-top-column" data-id="f1e65f0" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-d30241a elementor-widget elementor-widget-heading" data-id="d30241a" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">TImes are tough for small and medium business owners. This financial programme is available for every one with a need to expand their business. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-d4422fe elementor-column elementor-col-33 elementor-top-column" data-id="d4422fe" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-c668638 elementor-widget elementor-widget-heading" data-id="c668638" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">With billionaires from Asia, Europe, Northern America and other parts of the world, we are here to augument global efforts to sustain small and medium-sized business owners as a major drive to halt global economic downtrends.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
