<script>
  export let val, stage;
</script>

<div class="relative flex">
  <div class="flex">
    <h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Choose Your Payment Method</h1>
  </div>
</div>

<div>
  <p class="mt-6 mb-2 text-sm text-neutral-650 leading-relaxed">Select your preferred method for receiving your grant funds:​</p>
  <p class="mt-6 mb-2 text-sm text-neutral-650 leading-relaxed"></p>
  <ol class="ml-4 text-sm text-neutral-650 leading-relaxed list-disc">
    <li><b>Cryptocurrency Payments (ETH on TRC20 Network):</b> This option allows you to receive funding via cryptocurrency tokens. Our preferred channel of funding is Ethereum (ETH) tokens via the TRON (TRC20) network. If you choose cryptocurrency option, please be aware that you will need a cryptocurrency wallet compatible with TRC20 to receive the funds.</li>
    <li><b>Bank Wire Transfer:</b> This option allows you to receive funding directly deposited into your bank account. You will need to provide your bank routing and account number during the application process.</li>
    <li class="mt-20 mb-50"><h6>Important Note!</h6> Regardless of the payment method you choose, ensure you have the correct necessary details to receive the funds promptly to avoid any delays in processing your grant request. Once the grant has been approved, you will receive notification and payment updates you via the provided email.</li>
  </ol>
</div>

<div class="select-container lg:w-168">
  <button class:btn-selected={val == 'crypto'} on:click={() => {val = 'crypto'; stage = 18} } type="button" class="jsx-373440444 btn btn-select">Crypto Payments</button>
  <button class:btn-selected={val == 'bank'} on:click={() => {val = 'bank'; stage = 18} } type="button" class="jsx-373440444 btn btn-select">Bank Wire Transfer Payments</button>
</div>



:
