<script>
  export let appName;
</script>

<section class="elementor-element elementor-element-a49b8b1 elementor-section-stretched elementor-section-height-min-height elementor-section-items-top elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section" data-id="a49b8b1" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-0b0098c elementor-column elementor-col-50 elementor-top-column" data-id="0b0098c" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <section class="elementor-element elementor-element-e4a8ae6 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="e4a8ae6" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-ea01ee0 elementor-column elementor-col-50 elementor-inner-column" data-id="ea01ee0" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-ac84874 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" style="max-width: 750px; width: 750px; top: 5vh" data-id="ac84874" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-60,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1024" height="800" src="/img/smiling-mature-man-outdoors-with-mobile-phone-PB28ME5-683x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="elementor-element elementor-element-70a5bc6 elementor-column elementor-col-50 elementor-inner-column" data-id="70a5bc6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-1ded1d8 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" data-id="1ded1d8" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1024" height="1024" src="/img/business-people-discussion-happiness-coffee-PM2SKXD-1024x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-911586d elementor-column elementor-col-50 elementor-top-column" data-id="911586d" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-06eaae9 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="06eaae9" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="747" height="704" src="/img/hom3_morph_bg2.png" class="attachment-full size-full" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-ffa4299 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="ffa4299" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">What we are</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-747a263 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="747a263" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">We are optimists who love to work together</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-e9831ac elementor-widget elementor-widget-heading" data-id="e9831ac" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">{appName} is a financial programme brought up by a few bilionaire moguls to support small and medium business owners and help them expand and grow rapidly.</span>
              </div>
            </div>
            <div class="elementor-element elementor-element-bc37c97 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="bc37c97" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <a href="{route('app.careers')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-icon elementor-align-icon-right">
                        <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                      <span class="elementor-button-text">Work with us</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
