<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>


<script>
  import { page } from '@inertiajs/svelte';
  import CTA from './Partials/Home/CTA.svelte';
  import Hero from './Partials/Home/Hero.svelte';
  import WhatWeDo from './Partials/Home/WhatWeDo.svelte';
  import WhatWeAre from './Partials/Home/WhatWeAre.svelte';
  import LatestNews from './Partials/Home/LatestNews.svelte';
  import CoreValues from './Partials/Home/CoreValues.svelte';
  import Testimonials from './Partials/Home/Testimonials.svelte';
  import Covid19Relief from './Partials/Home/Covid19Relief.svelte';
  import GrantStatistics from './Partials/Home/GrantStatistics.svelte';

  $: ({ app } = $page.props);
</script>

<div class="inner">
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="4309" class="elementor elementor-4309" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <Hero />
            <WhatWeDo />
            <Covid19Relief />
            <WhatWeAre appName={app.name}/>
            <GrantStatistics />
            <Testimonials appName={app.name}/>
            <CoreValues />
            <LatestNews />
            <CTA />
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
</div>
