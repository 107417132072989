<section class="elementor-element elementor-element-618c186 elementor-section-stretched elementor-section-height-min-height elementor-section-items-top elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section" data-id="618c186" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-4887cda elementor-column elementor-col-50 elementor-top-column" data-id="4887cda" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-070b2a8 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="070b2a8" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Important Notice</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-b479953 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="b479953" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">COVID-19 Relief Grants</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-e4df1e3 elementor-widget elementor-widget-heading" data-id="e4df1e3" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">We’re unable to accept new applications for COVID-19 relief grants at the moment. However we continue to offer small and medium-sized business owners access to grants after due diligence has been performed by our review and verification teams. </span>
              </div>
            </div>
            <div class="elementor-element elementor-element-fea5e2f elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="fea5e2f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default"><a href="{route('app.about')}">read more about us</a></span>
              </div>
            </div>
            <div class="elementor-element elementor-element-bb2f5f5 elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon" data-id="bb2f5f5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <a class="elementor-icon" href="#">
                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-183185d elementor-column elementor-col-50 elementor-top-column" data-id="183185d" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <section class="elementor-element elementor-element-b6894f7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="b6894f7" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-c9661ec elementor-column elementor-col-50 elementor-inner-column" data-id="c9661ec" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-3de2828 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image img-b" data-id="3de2828" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1024" height="1024" src="/img/business-colleagues-conference-teamwork-ideas-PKP7WYL-1024x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-fdded81 elementor-column elementor-col-50 elementor-inner-column" data-id="fdded81" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-9654dea elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget elementor-widget-image img-t elementor-hidden-phone" data-id="9654dea" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1024" height="1024" src="/img/attractive-business-woman-working-at-his-laptop-P6AMUS6-1024x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .img-b{
    z-index: 1 !important;
    max-width: 500px !important;
    top: 70px !important;
    width: 500px !important;

    @media (max-width: 991px) {
      top: inherit !important;
    }
  }

  .img-t{
    z-index: 2 !important;
    max-width: 600px !important;
    top: 200px !important;
    width: 600px !important;
  }
</style>
