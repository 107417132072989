<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>


<script>
  import { page, router } from '@inertiajs/svelte';

  $: ({ app } = $page.props);

  let details = {},

  startApplication = () => {
    router.post(route('app.application.start'), details);
  }
</script>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5858" class="elementor elementor-5858" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section class="elementor-element elementor-element-46d3f542 elementor-section-stretched elementor-section-height-min-height elementor-section-items-bottom elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section" data-id="46d3f542" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-57cd0272 elementor-column elementor-col-50 elementor-top-column" data-id="57cd0272" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-2df6a96f elementor-column elementor-col-50 elementor-top-column" data-id="2df6a96f" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-105ec65 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="105ec65" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-8fa43c6 elementor-column elementor-col-50 elementor-top-column" data-id="8fa43c6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-336d275 elementor-widget elementor-widget-heading" data-id="336d275" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">{app.name} provides limited small business grants and grants to eligible community organizations and SMEs to help entrepreneurs find their footing in an ever changing business landscape.</span>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-38cdfb0 elementor-widget elementor-widget-image" data-id="38cdfb0" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="380" height="104" src="/img/home3_signature.png" class="attachment-full size-full" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-e78c69d elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="e78c69d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">Grayson Moore – Executive Director</span>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-cb156c2 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="cb156c2" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.5,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.5,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1494" height="1408" src="/img/home2_morph_bg1.png" class="attachment-full size-full" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-65b43c9 elementor-column elementor-col-50 elementor-top-column" data-id="65b43c9" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-c33a02d elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="c33a02d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Start Grant Application</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-dc98c8f elementor-widget elementor-widget-text-editor" data-id="dc98c8f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                <p class="p1">
                                  Please complete the details below to begin applying for your business grant
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-2ef44e6 elementor-widget elementor-widget-shortcode" data-id="2ef44e6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="shortcode.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-shortcode">
                              <div role="form" class="wpcf7" id="wpcf7-f5-p5858-o1" lang="en-US" dir="ltr">
                                <div class="screen-reader-response">
                                </div>
                                <form class="quform" on:submit|preventDefault|stopPropagation="{startApplication}">

                                  <div class="quform-elements">
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <input bind:value="{details.first_name}" id="name" type="text" name="first_name" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="First Name*">
                                      </span>

                                    </div>
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-email">
                                        <input bind:value="{details.email}" id="email" type="text" name="email" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="Email*">
                                      </span>

                                    </div>
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-grant-reason">
                                        <textarea bind:value="{details.grant_reason}" id="grant-reason" name="grant-reason" cols="40" rows="5" class="input1" aria-invalid="false" placeholder="Reason for Grant*"></textarea>
                                      </span>

                                    </div>

                                    <!-- Begin Submit button -->
                                    <div class="quform-submit">
                                      <div class="quform-submit-inner">
                                        <button type="submit" class="submit-button"><span>Send</span></button>
                                      </div>
                                      <div class="quform-loading-wrap"><span class="quform-loading"></span></div>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-00d3fb3 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="00d3fb3" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-b09c07e elementor-column elementor-col-100 elementor-top-column" data-id="b09c07e" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-ffe5769 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="ffe5769" data-element_type="widget" data-settings='&#123;"avante_ext_is_infinite":"true","avante_ext_infinite_animation":"if_scale","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-b7f647f elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="b7f647f" data-element_type="widget" data-settings='&#123;"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="70" height="70" src="/img/orange_bubble-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-747e0c7 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="747e0c7" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":200,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":-360,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="150" height="120" src="/img/orange_triangle2.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-2ff477d elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="2ff477d" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatex":&#123;"unit":"px","size":300,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":60,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":360,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":360,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="190" height="150" src="/img/orange_triangle-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-9283879 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="9283879" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatex":&#123;"unit":"px","size":-200,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":60,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":-360,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="190" height="150" src="/img/orange_triangle-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-2762273 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="2762273" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":-50,"sizes":[]&#125;,"avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-0427716 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="0427716" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_rotatey":&#123;"unit":"px","size":360,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":-200,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":-70,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="70" height="70" src="/img/orange_bubble-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-020f972 elementor-widget elementor-widget-heading" data-id="020f972" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">We are here to support your entrepreneurial journey</h1>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-db71f74 elementor-widget elementor-widget-heading" data-id="db71f74" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">Get convenient grants of up to $5 million today. Apply now to grow  your business dreams.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-4b19175f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4b19175f" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-46a5684 elementor-column elementor-col-33 elementor-top-column" data-id="46a5684" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-6f705f9e animated-fast elementor-invisible elementor-widget elementor-widget-counter" data-id="6f705f9e" data-element_type="widget" data-settings='&#123;"_animation":"fadeInUp","_animation_delay":100,"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="2000" data-to-value="70" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix">+</span>
                              </div>
                              <div class="elementor-counter-title">
                                Grants Awarded
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-216f7c26 elementor-column elementor-col-33 elementor-top-column" data-id="216f7c26" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-66c9a03e animated-fast elementor-invisible elementor-widget elementor-widget-counter" data-id="66c9a03e" data-element_type="widget" data-settings='&#123;"_animation":"fadeInUp","_animation_delay":200,"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="2000" data-to-value="97" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix">%</span>
                              </div>
                              <div class="elementor-counter-title">
                                Satisfaction
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-349e65c0 elementor-column elementor-col-33 elementor-top-column" data-id="349e65c0" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-10b9c99b animated-fast elementor-invisible elementor-widget elementor-widget-counter" data-id="10b9c99b" data-element_type="widget" data-settings='&#123;"_animation":"fadeInUp","_animation_delay":300,"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="500" data-to-value="10" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix">x</span>
                              </div>
                              <div class="elementor-counter-title">
                                Return Rate
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
  <!-- End main content -->
</div>
