<script>
  export let style = '', placeholder="Enter Value", errMsg="The value is invalid", fullWidth = false;

  let val;

  export {val};
</script>

<style lang="scss" global>
  /* Video Explanation - https://youtu.be/3AK3vspZvvM */

  .wrapper{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    font-size: 16px;

    &.full-width{
      width: 80%;
      margin-left: 10%;

      .custom-field, input{
        width: 100%;
      }
    }
  }


  .custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  --field-padding: 12px;

  *, *::before, *::after {
    box-sizing: border-box;
  }
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition:
    top 0.3s ease,
    color 0.3s ease,
    font-size 0.3s ease;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

.custom-field .error-message {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  background: #d30909;
  color: #fff;
  height: 24px;
}

.custom-field .error-message:empty {
  opacity: 0;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
}

.custom-field.one input.dirty,
.custom-field.one input:not(:placeholder-shown),
.custom-field.one input:focus {
  border-color: #222;
  transition-delay: 0.1s
}

.custom-field.one input.dirty + .placeholder,
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #222;
  background: #fff;
  width: auto
}

/* TWO */
.custom-field.two input {
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background:
    linear-gradient(90deg, #222, #222) center bottom/0 0.15em no-repeat,
    linear-gradient(90deg, #ccc, #ccc) left bottom/100% 0.15em no-repeat,
    linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat;
  transition: background-size 0.3s ease;
}

.custom-field.two input.dirty,
.custom-field.two input:not(:placeholder-shown),
.custom-field.two input:focus {
  background-size: 100% 0.15em, 100% 0.1em, 100%;
}

/* THREE */
.custom-field.three {
  --draw-duration: 0.1s;
  --draw-color: #222;
  --draw-line-width: 2px;
  --draw-easing: linear;
}

.custom-field.three .border {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: none;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-radius: 3px;
}

.custom-field.three .border::after,
.custom-field.three .border::before {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  border-radius: 3px;
}

.custom-field.three .border::before {
  left: 0;
  bottom: 0;
  border-right: 0px solid var(--draw-color);
  border-bottom: 0px solid var(--draw-color);
  transition:
    border 0s linear calc(var(--draw-duration) * 4),
    height var(--draw-duration) var(--draw-easing) calc(var(--draw-duration) * 2),
    width var(--draw-duration) var(--draw-easing) calc(var(--draw-duration) * 3);
}

.custom-field.three .border::after {
  right: 0;
  top: 0;
  border-left: 0px solid var(--draw-color);
  border-top: 0px solid var(--draw-color);
  transition:
    border 0s linear calc(var(--draw-duration) * 2),
    height var(--draw-duration) var(--draw-easing),
    width var(--draw-duration) var(--draw-easing) var(--draw-duration);
}

.custom-field.three input:focus ~ .border::before,
.custom-field.three input:not(:placeholder-shown) ~ .border::before,
.custom-field.three input.dirty ~ .border::before,
.custom-field.three input:focus ~ .border::after,
.custom-field.three input:not(:placeholder-shown) ~ .border::after,
.custom-field.three input.dirty ~ .border::after {
  width: 100%;
  height: 100%;
  border-width: var(--draw-line-width);
}

.custom-field.three input:not(:placeholder-shown) ~ .border::before,
.custom-field.three input.dirty ~ .border::before,
.custom-field.three input:focus ~ .border::before {
  transition-delay: 0s, var(--draw-duration), 0s;
}

.custom-field.three input:not(:placeholder-shown) ~ .border::after,
.custom-field.three input.dirty ~ .border::after,
.custom-field.three input:focus ~ .border::after {
  transition-delay:
    calc(var(--draw-duration) * 2),
    calc(var(--draw-duration) * 3),
    calc(var(--draw-duration) * 2);
}
</style>


<div class="wrapper" class:full-width="{fullWidth}">
  <label class="custom-field {style}">
    <input placeholder="&nbsp;" bind:value="{val}"/>
    <span class="placeholder">{placeholder}</span>
    {#if style == ""}
      <span class="error-message" aria-live="polite">{errMsg}</span>
    {:else if style == "three"}
      <span class="border"></span>
    {/if}
  </label>
</div>
