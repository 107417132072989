<div class="elementor-element elementor-element-e1d8243 elementor-column elementor-col-25 elementor-inner-column" data-id="e1d8243" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;'>
  <div class="elementor-column-wrap elementor-element-populated">
    <div class="elementor-widget-wrap align-start">
      <div class="elementor-element elementor-element-5f0821e elementor-widget elementor-widget-image" data-id="5f0821e" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":600,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
        <div class="elementor-widget-container mb-5 sm-mb-30">
          <div class="elementor-image">
            <img width="152" height="156" style="width: 70px;" src="/img/home2_icon2.png" class="attachment-large size-large" alt="">
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-e24804b elementor-widget elementor-widget-heading" data-id="e24804b" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Eligibility Criteria</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-3b5ced0 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="3b5ced0" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
        <div class="elementor-widget-container">
          <div class="elementor-divider" style="{`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ef3d5b' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e ");`}">
            <span class="elementor-divider-separator">
            </span>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-ce1b5c2 elementor-widget elementor-widget-text-editor" data-id="ce1b5c2" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <div class="elementor-text-editor elementor-clearfix">
            <div class="lqd-lines split-unit lqd-unit-animation-done">
              <p class="p1 text-justify">
                <b class="mt-10 d-block text-left">Entrepreneurial Vision:</b> Applicants must demonstrate a clear vision for their business and its potential for growth and impact.
                <br>
                <b class="mt-10 d-block text-left">Financial Need:</b> Priority is given to applicants who can articulate a genuine need for financial support to overcome specific challenges or capitalize on opportunities.
                <br>
                <b class="mt-10 d-block text-left">Innovation:</b> We seek to support ventures that exhibit creativity, innovation, and the potential to disrupt industries or create positive social change.
                <br>
                <b class="mt-10 d-block text-left">Legal Compliance</b> Grant recipients must adhere to all relevant laws and regulations governing their business operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="elementor-element elementor-element-4e66437 elementor-column elementor-col-25 elementor-inner-column" data-id="4e66437" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
  <div class="elementor-column-wrap elementor-element-populated">
    <div class="elementor-widget-wrap align-start">
      <div class="elementor-element elementor-element-4d3f9b9 elementor-widget elementor-widget-image" data-id="4d3f9b9" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
        <div class="elementor-widget-container">
          <div class="elementor-image">
            <img width="184" height="116" style="width: 92px;" src="/img/home2_icon1.png" class="attachment-large size-large" alt="">
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-6a0d72d elementor-widget elementor-widget-heading" data-id="6a0d72d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Application Process</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-a2bc090 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="a2bc090" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
        <div class="elementor-widget-container">
          <div class="elementor-divider" style="{`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ef3d5b' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e ");`}">
            <span class="elementor-divider-separator">
            </span>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-9ec7eca elementor-widget elementor-widget-text-editor" data-id="9ec7eca" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <div class="elementor-text-editor elementor-clearfix">
            <div class="lqd-lines split-unit lqd-unit-animation-done">
              <p class="p1 text-justify">
                <b class="text-left mt-10 d-block">Online Application:</b> Prospective applicants can submit their grant proposals through our user-friendly online platform.
                <br>
                <b class="text-left mt-10 d-block">Review Process:</b> Each application undergoes a comprehensive review process by our expert panel, evaluating factors such as feasibility, scalability, and impact.
                <br>
                <b class="text-left mt-10 d-block">Decision and Notification:</b> Successful applicants will be notified promptly upon approval, with detailed instructions on payment options and next steps.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="elementor-element elementor-element-fd34e98 elementor-column elementor-col-25 elementor-inner-column" data-id="fd34e98" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
  <div class="elementor-column-wrap elementor-element-populated">
    <div class="elementor-widget-wrap align-start">
      <div class="elementor-element elementor-element-0634f2c elementor-widget elementor-widget-image" data-id="0634f2c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
        <div class="elementor-widget-container">
          <div class="elementor-image">
            <img width="100" height="124" src="/img/home2_icon3.png" class="attachment-large size-large" alt="">
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-a815dea elementor-widget elementor-widget-heading" data-id="a815dea" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Payment Options</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-2a9e2b0 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="2a9e2b0" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
        <div class="elementor-widget-container">
          <div class="elementor-divider" style="{`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ef3d5b' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e ");`}">
            <span class="elementor-divider-separator">
            </span>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-5f7e21b elementor-widget elementor-widget-text-editor" data-id="5f7e21b" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <div class="elementor-text-editor elementor-clearfix">
            <div class="lqd-lines split-unit lqd-unit-animation-done">
              <p class="p1 text-justify">
                With over 3,500+ business cases, we take pride fighting for and growing individuals and small businesses weather the challenges of the ever-changing economic upheavals, not big insurance companies.
                <br>
                To ensure convenience and flexibility, grant recipients have the option to receive payments in cryptocurrency via Ethereum-based ERC20 Tokens,BNB Smart chain network BEP20 or via bank wire transfer. This innovative approach reflects our commitment to embracing emerging technologies and meeting the evolving needs of our recipients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="elementor-element elementor-element-5b28d6d elementor-column elementor-col-25 elementor-inner-column" data-id="5b28d6d" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
  <div class="elementor-column-wrap elementor-element-populated">
    <div class="elementor-widget-wrap align-start">
      <div class="elementor-element elementor-element-b9a2f91 elementor-widget elementor-widget-image" data-id="b9a2f91" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
        <div class="elementor-widget-container">
          <div class="elementor-image">
            <img width="156" height="132" src="/img/home2_icon4.png" class="attachment-large size-large" alt="">
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-bfaab2b elementor-widget elementor-widget-heading" data-id="bfaab2b" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Global Reach</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-c8bfc19 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="c8bfc19" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
        <div class="elementor-widget-container">
          <div class="elementor-divider" style="{`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ef3d5b' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e ");`}">
            <span class="elementor-divider-separator">
            </span>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-5378324 elementor-widget elementor-widget-text-editor" data-id="5378324" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <div class="elementor-text-editor elementor-clearfix">
            <div class="lqd-lines split-unit lqd-unit-animation-done">
              <p class="p1 text-justify">
                Operated in 15+ countries, our programs fund a diverse portfolio of startups and small businesses across a wide range of markets to stimulate technological innovation across multiple countries and district areas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
