<script>
  export let app = {};
</script>

<div id="elementor-header" class="main-menu-wrapper">
  <div data-elementor-type="wp-post" data-elementor-id="4287" class="elementor elementor-4287" data-elementor-settings="[]">
    <div class="elementor-inner">
      <div class="elementor-section-wrap">
        <section class="elementor-element elementor-element-4b45b7c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4b45b7c" data-element_type="section" data-settings='&#123;"background_background":"classic"&#125;'>
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-row">
              <div class="elementor-element elementor-element-aaf3640 elementor-column elementor-col-50 elementor-top-column" data-id="aaf3640" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                <div class="elementor-column-wrap elementor-element-populated">
                  <div class="elementor-widget-wrap">
                    <div class="elementor-element elementor-element-fddc123 elementor-icon-list--layout-inline elementor-mobile-align-center elementor-widget elementor-widget-icon-list" data-id="fddc123" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon-list.default">
                      <div class="elementor-widget-container">
                        <ul class="elementor-icon-list-items elementor-inline-items">
                          <li class="elementor-icon-list-item">
                            <a href="tel:{app.phone}"><span class="elementor-icon-list-icon">
                              <i aria-hidden="true" class="fas fa-phone-alt"></i></span>
                              <span class="elementor-icon-list-text">{app.phone}</span>
                            </a>
                          </li>
                          <li class="elementor-icon-list-item">
                            <a href="mailto:{app.email}"><span class="elementor-icon-list-icon">
                              <i aria-hidden="true" class="far fa-envelope"></i></span>
                              <span class="elementor-icon-list-text">{app.email}</span>
                            </a>
                          </li>
                          <li class="elementor-icon-list-item">
                            <span class="elementor-icon-list-icon">
                              <i aria-hidden="true" class="far fa-clock"></i></span>
                            <span class="elementor-icon-list-text">{app.working_hours}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="elementor-element elementor-element-4cd1643 elementor-column elementor-col-50 elementor-top-column" data-id="4cd1643" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                <div class="elementor-column-wrap elementor-element-populated">
                  <div class="elementor-widget-wrap">
                    <div class="elementor-element elementor-element-1aef7dd elementor-widget__width-auto elementor-hidden-phone elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="1aef7dd" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
                      <div class="elementor-widget-container">
                        <div class="elementor-button-wrapper">
                          <a href="{route('app.application.start')}" class="elementor-button-link elementor-button elementor-size-sm" role="button">
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-icon elementor-align-icon-left">
                                <i aria-hidden="true" class="fab fa-buffer"></i></span>
                              <span class="elementor-button-text">Apply Now</span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="elementor-element elementor-element-6242961 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="6242961" data-element_type="section" data-settings='&#123;"background_background":"classic"&#125;'>
          <div class="elementor-container elementor-column-gap-no">
            <div class="elementor-row">
              <div class="elementor-element elementor-element-3230b93 elementor-column elementor-col-50 elementor-top-column" data-id="3230b93" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                <div class="elementor-column-wrap elementor-element-populated">
                  <div class="elementor-widget-wrap">
                    <div class="elementor-element elementor-element-ac7463f elementor-widget elementor-widget-image" data-id="ac7463f" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                      <div class="elementor-widget-container">
                        <div class="elementor-image">
                          <a data-elementor-open-lightbox="" href="{route('app.home')}">
                            <img width="228" height="54" src="/img/logo2.png" class="attachment-full size-full" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="elementor-element elementor-element-2a5d3b6 elementor-column elementor-col-50 elementor-top-column" data-id="2a5d3b6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                <div class="elementor-column-wrap elementor-element-populated">
                  <div class="elementor-widget-wrap">
                    <div class="elementor-element elementor-element-11ced52 elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-avante-navigation-menu" data-id="11ced52" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="avante-navigation-menu.default">
                      <div class="elementor-widget-container">
                        <div class="themegoods-navigation-wrapper menu_style1">
                          <div class="menu-main-menu-container">
                            <ul id="nav_menu36" class="nav">
                              <li class='menu-item' class:current-menu-item={ route().current('app.home') }>
                                <a href="{ route('app.home') }">Home</a>
                              </li>
                              <li class='menu-item' class:current-menu-item={ route().current('app.credit') }>
                                <a href="{ route('app.credit') }">Business Line of Credit</a>
                              </li>
                              <li class='menu-item' class:current-menu-item={ route().current('app.about') }>
                                <a href="{ route('app.about') }">About Us</a>
                              </li>
                              <li class='menu-item' class:current-menu-item={ route().current('app.careers') }>
                                <a href="{ route('app.careers') }">Careers</a>
                              </li>
                              <li class='menu-item' class:current-menu-item={ route().current('app.contact') }>
                                <a href="{ route('app.contact') }">Contact Us</a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="elementor-element elementor-element-2586fbd elementor_mobile_nav elementor-widget__width-auto elementor-hidden-desktop elementor-view-default elementor-widget elementor-widget-icon" data-id="2586fbd" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                      <div class="elementor-widget-container">
                        <div class="elementor-icon-wrapper">
                          <a class="elementor-icon" href="javascript:;">
                            <i aria-hidden="true" class="fas fa-ellipsis-v"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
