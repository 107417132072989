<script>
  /**
   * ! Whatever you export like this can be bound to in the component eg bind:val={details.property}
   */
   export let val, stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">What type of Business do you own?</h1>
<div class="select-container lg:w-168">
  <button class:btn-selected={val == 'Sole Proprietorship'} on:click={() => {val = 'Sole Proprietorship'; stage = 2} } type="button" class="jsx-373440444 btn btn-select">Sole Proprietorship</button>
  <button class:btn-selected={val == 'Partnership'} on:click={() => {val = 'Partnership'; stage = 2} } type="button" class="jsx-373440444 btn btn-select">Partnership</button>
  <button class:btn-selected={val == 'Limited Liability Company'} on:click={() => {val = 'Limited Liability Company | LLC'; stage = 2} } type="button" class="jsx-373440444 btn btn-select">Limited Liability Company | LLC</button>
  <button class:btn-selected={val == 'C Corporation'} on:click={() => {val = 'C Corporation'; stage = 2} } type="button" class="jsx-373440444 btn btn-select">C Corporation</button>
  <button class:btn-selected={val == 'S Corporation'} on:click={() => {val = 'S Corporation'; stage = 2} } type="button" class="jsx-373440444 btn btn-select">S Corporation</button>
</div>
