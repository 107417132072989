<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>


<script>
  import { page, router } from '@inertiajs/svelte';

  $: ({ app } = $page.props);

  let details = {}, isLoading = false,

  contactUs = () => {
    isLoading = true;

    router.post(route('app.contact'), details, {
      onSuccess: () => details = {},
      onFinish: () => isLoading = false,
    });
  }
</script>

<style>
  .submit-button.disabled{
    background-color: lightgray;
    border-color: gray;
    pointer-events: none;
    cursor: not-allowed;
  }
</style>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5879" class="elementor elementor-5879" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section class="elementor-element elementor-element-172e078 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="172e078" data-element_type="section" data-settings='&#123;&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;curve&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;&#125;'>
              <div class="elementor-background-overlay">
              </div>
              <div class="elementor-shape elementor-shape-bottom" data-negative="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1000 100" preserveaspectratio="none">
                  <path class="elementor-shape-fill" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
                </svg>
              </div>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-05f8f5e elementor-column elementor-col-100 elementor-top-column" data-id="05f8f5e" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-1ec7424 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="1ec7424" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">Contact Us</h1>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-7279b2d elementor-widget elementor-widget-text-editor" data-id="7279b2d" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                <!-- Salvia esse nihil, flexitarian Truffaut synth art party deep v chillwave. Seitan High Life reprehenderit consectetur cupidatat kogi. -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-9a6c678 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="9a6c678" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-d7d3994 elementor-column elementor-col-100 elementor-top-column" data-id="d7d3994" data-element_type="column" data-settings='&#123;&quot;background_background&quot;:&quot;classic&quot;,&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:500,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-b9a3450 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="b9a3450" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_scale&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-36014db elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="36014db" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_bounce&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-45db939 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="45db939" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_infinite&quot;:&quot;true&quot;,&quot;avante_ext_infinite_duration&quot;:3,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_infinite_animation&quot;:&quot;if_bounce&quot;,&quot;avante_ext_infinite_easing&quot;:&quot;0.250, 0.250, 0.750, 0.750&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="70" height="70" src="/img/orange_bubble-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-8fe9ddf elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="8fe9ddf" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:200,&quot;sizes&quot;:[]&#125;,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="150" height="120" src="/img/orange_triangle2.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-18d063a elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="18d063a" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]&#125;,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:70,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="150" height="120" src="/img/orange_triangle2.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-ca097ab elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="ca097ab" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:300,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="190" height="150" src="/img/orange_triangle-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-ad1748f elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="ad1748f" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-200,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:60,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="190" height="150" src="/img/orange_triangle-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-ceb5275 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="ceb5275" data-element_type="widget" data-settings='&#123;&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_disable&quot;:&quot;tablet&quot;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-100,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-50,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-fecfb73 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="fecfb73" data-element_type="widget" data-settings='&#123;&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_disable&quot;:&quot;tablet&quot;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-100,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-50,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="90" height="90" src="/img/purple_bubble.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-fa1c2b9 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="fa1c2b9" data-element_type="widget" data-settings='&#123;&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:360,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-200,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-70,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="70" height="70" src="/img/orange_bubble-1.png" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-c76081f elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="c76081f" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:500,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Send us a message</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-787274d elementor-widget elementor-widget-text-editor" data-id="787274d" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:500,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                <p class="p1">
                                  Please complete the details below and then click on Submit and we&#8217;ll be in contact
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-ba51080 elementor-widget elementor-widget-shortcode" data-id="ba51080" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:500,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.8,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="shortcode.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-shortcode">
                              <div role="form" class="wpcf7" id="wpcf7-f5-p5879-o1" lang="en-US" dir="ltr">
                                <div class="screen-reader-response">
                                </div>
                                <form class="quform" on:submit|preventDefault|stopPropagation="{contactUs}">

                                  <div class="quform-elements">
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <input bind:value={details.name} id="name" type="text" name="name" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="Name*">
                                      </span>

                                    </div>
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-email">
                                        <input bind:value={details.email} id="email" type="text" name="email" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="Email*">
                                      </span>

                                    </div>
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-message">
                                        <textarea bind:value={details.message} id="message" name="message" cols="40" rows="5" class="input1" aria-invalid="false" placeholder="Message*"></textarea>
                                      </span>

                                    </div>

                                    <div class="quform-submit">
                                      <div class="quform-submit-inner">
                                        <button type="submit" class="submit-button" class:disabled={isLoading}><span>Send</span></button>
                                      </div>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-22da79c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="22da79c" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-fc6001e elementor-column elementor-col-100 elementor-top-column" data-id="fc6001e" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-f0e2c45 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="f0e2c45" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Contact</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-e73cc9d elementor-absolute elementor-widget elementor-widget-image" data-id="e73cc9d" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;true&quot;,&quot;avante_ext_scrollme_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-150,&quot;sizes&quot;:[]&#125;,&quot;_position&quot;:&quot;absolute&quot;,&quot;avante_ext_scrollme_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.2,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1.2,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-100,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_disable&quot;:&quot;mobile&quot;,&quot;avante_ext_scrollme_smoothness&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:30,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_scalez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_scrollme_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="672" height="672" src="/img/home3_oval_bg2.png" class="attachment-full size-full" alt="" srcset="/img/home3_oval_bg2.png 672w, /img/home3_oval_bg2-150x150.png 150w, /img/home3_oval_bg2-300x300.png 300w, /img/home3_oval_bg2-440x440.png 440w, /img/home3_oval_bg2-610x610.png 610w" sizes="(max-width: 672px) 100vw, 672px">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-8285512 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="8285512" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Get in touch</h2>
                          </div>
                        </div>
                        <section class="elementor-element elementor-element-f20b5d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="f20b5d2" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-789ea01 elementor-column elementor-col-33 elementor-inner-column" data-id="789ea01" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-69fb95c elementor-widget elementor-widget-image" data-id="69fb95c" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:600,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <!-- <img width="300" height="272" src="/img/home3_usa.png" class="attachment-full size-full" alt=""> -->
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-d027a98 elementor-widget elementor-widget-heading" data-id="d027a98" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Office Address</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-0a230b6 elementor-widget elementor-widget-text-editor" data-id="0a230b6" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <p class="p1">
                                            {app.address}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-bbf15b3 elementor-column elementor-col-33 elementor-inner-column" data-id="bbf15b3" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-2013ac2 elementor-widget elementor-widget-image" data-id="2013ac2" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:600,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <!-- <img width="260" height="270" src="/img/home3_france.png" class="attachment-full size-full" alt=""> -->
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-9f79380 elementor-widget elementor-widget-heading" data-id="9f79380" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Email</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-5765dfd elementor-widget elementor-widget-text-editor" data-id="5765dfd" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <p class="p1">
                                            {app.email}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-c223a2e elementor-column elementor-col-33 elementor-inner-column" data-id="c223a2e" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-5fea69d elementor-widget elementor-widget-image" data-id="5fea69d" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:600,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0.7,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <!-- <img width="280" height="286" src="/img/home3_thailand.png" class="attachment-large size-large" alt=""> -->
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2d74247 elementor-widget elementor-widget-heading" data-id="2d74247" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Working Hours</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-8b53977 elementor-widget elementor-widget-text-editor" data-id="8b53977" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <p class="p1">
                                            {app.working_hours}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-a09f6e4 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="a09f6e4" data-element_type="section" data-settings='&#123;&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;gradient&quot;&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-5c36575 elementor-column elementor-col-66 elementor-top-column" data-id="5c36575" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-ea876c2 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="ea876c2" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Ready to get your business grant?</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-5fa095d elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="5fa095d" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_duration&quot;:1000,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-heading-title elementor-size-default">
                              <!-- Begin Application Now -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-4355f1e elementor-column elementor-col-33 elementor-top-column" data-id="4355f1e" data-element_type="column" data-settings='&#123;&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-13bca61 elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="13bca61" data-element_type="widget" data-settings='&#123;&quot;avante_ext_is_smoove&quot;:&quot;true&quot;,&quot;avante_ext_smoove_disable&quot;:&quot;769&quot;,&quot;avante_ext_smoove_rotatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:40,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-140,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_scrollme&quot;:&quot;false&quot;,&quot;avante_ext_smoove_duration&quot;:400,&quot;avante_ext_smoove_scalex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_scaley&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatey&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_rotatez&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_translatex&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewx&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_skewy&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_smoove_perspective&quot;:&#123;&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1000,&quot;sizes&quot;:[]&#125;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;&#125;' data-widget_type="button.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a href="{route('app.application.start')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-icon elementor-align-icon-right">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                                  <span class="elementor-button-text">Begin Application</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
  <!-- End main content -->
</div>
