<style>
  .mini-title{
    font-family: GlacialIndifference-Bold, Sans-serif;
    font-size: 28px;
    line-height: 1.2em;
    color: #fff;
  }
</style>

<section class="elementor-element elementor-element-5ac5a52 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section pb-40" data-id="5ac5a52" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"tilt"&#125;'>
  <div class="elementor-shape elementor-shape-bottom" data-negative="false">
    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1000 100" preserveaspectratio="none">
      <path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
    </svg>
  </div>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-10e9a05 elementor-column elementor-col-33 elementor-top-column" data-id="10e9a05" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated sm-mt-50">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-c3f3cb1 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="c3f3cb1" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h1 class="elementor-heading-title elementor-size-default">Our Mission</h1>
              </div>
            </div>
            <div class="elementor-element elementor-element-d455920 elementor-widget elementor-widget-text-editor" data-id="d455920" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <div class="lqd-lines split-unit lqd-unit-animation-done">
                    The mission of this successful organization is to empower Small to Medium Enterprises / Business Owners to achieve and maintain its  financial fitness. We aim to provide expansion opportunities to over 3,000 business organizations each year.

                    <h2 class="mini-title my-20">Benefits of the Pinnacle Business Grants Program:</h2>

                    <b>Financial Support:</b> Our grants provide crucial financial resources to help entrepreneurs overcome barriers and fuel their growth trajectory.
                    <br>
                    <b>Networking Opportunities:</b> Grant recipients gain access to a vibrant community of like-minded entrepreneurs, mentors, and industry experts, facilitating valuable connections and collaborations.
                    <br>
                    <b>Visibility and Recognition:</b> We celebrate the achievements of our grant recipients, amplifying their stories and providing visibility through our network and channels.
                    <br>
                    <br>
                    At Pinnacle Business Grants, we believe in the power of entrepreneurship to drive economic growth and positive change. By providing financial support, fostering innovation, and building a supportive ecosystem, we empower entrepreneurs to reach new heights of success and impact.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-bd28f10 elementor-column elementor-col-66 elementor-top-column align-items-start" data-id="bd28f10" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-b9f6e5d elementor-absolute elementor-widget elementor-widget-image" data-id="b9f6e5d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":100,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container mt-200 sm-mt-40">
                <div class="elementor-image">
                  <img width="1066" height="1058" src="/img/service1_yellow_circle.png" class="attachment-full size-full" alt="" srcset="/img/service1_yellow_circle.png 1066w, /img/service1_yellow_circle.png 300w, /img/service1_yellow_circle.png 1024w, /img/service1_yellow_circle.png 150w, /img/service1_yellow_circle.png 768w, /img/service1_yellow_circle.png 440w" sizes="(max-width: 1066px) 100vw, 1066px">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-eded2fd animation mobile_static elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="eded2fd" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container mt-70 sm-mt-0">
                <div class="elementor-image">
                  <img width="1440" height="956" src="/img/young-businesswoman-smiling-at-office-LGQRJY2.jpg" class="attachment-full size-full" alt="" srcset="/img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 1440w, /img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 300w, /img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 768w, /img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 1024w, /img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 700w, /img/young-businesswoman-smiling-at-office-LGQRJY2.jpg 440w" sizes="(max-width: 1440px) 100vw, 1440px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
