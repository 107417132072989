<script>
  export let stage,
    firstName = undefined,
    lastName = undefined,
    phone = undefined,
    email = undefined,
    dob = undefined,
    pob = undefined,
    officeTitle = undefined,
    ownershipPercent = undefined;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Business Owner Information</h1>

<div class="input-container fluid">
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="firstName">First Name</label>
      <div class="w-full my-auto">
        <input bind:value="{firstName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="given-name" autocorrect="off" id="firstName" name="fname" placeholder="First Name" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="lastName">Last Name</label>
      <div class="w-full my-auto">
        <input bind:value="{lastName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="family-name" autocorrect="off" id="lastName" name="lname" placeholder="Last Name" type="text">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="phone">Phone Number</label>
      <div class="w-full my-auto">
        <input bind:value="{phone}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="tel" autocorrect="off" id="phone" name="phone" placeholder="Phone Number" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="email">Email</label>
      <div class="w-full my-auto">
        <input bind:value="{email}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="email" autocorrect="off" id="email" name="email" placeholder="Email" type="text">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="dob">Date of Birth</label>
      <div class="w-full my-auto">
        <input bind:value="{dob}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="bday" autocorrect="off" id="dob" name="dob" placeholder="Date of Birth (DD/MM/YYYY)" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="placeOfBirth">Place of Birth</label>
      <div class="w-full my-auto">
        <input bind:value="{pob}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="country-name" autocorrect="off" id="placeOfBirth" name="placeOfBirth" placeholder="Place of Birth" type="text">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="officeTitle">Title/Office</label>
      <div class="w-full my-auto">
        <input bind:value="{officeTitle}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocomplete="organization-title" autocorrect="off" id="officeTitle" name="officeTitle" placeholder="Office/Title" type="text">
      </div>
    </div>
    <div class="w-full">
      <span class="absolute ml-3 text-lg text-gray-500 percent-sign-format">%</span>
      <label class="form-input-label" for="ownershipPercent">Ownership Percentage</label>
      <div class="w-full my-auto">
        <input bind:value="{ownershipPercent}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="ownershipPercent" name="ownershipPercent" placeholder="Ownership Percentage" type="num" max="100">
      </div>
    </div>
  </div>
  <button on:click={() => stage = 13 } type="button" class="jsx-373440444 btn btn-continue ">Back</button>
  <button disabled={! ownershipPercent || ! firstName}  on:click={() => stage = 15 } type="button" class="jsx-373440444 btn btn-continue ">Continue</button>
</div>
