<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>

<script>
  import { page } from '@inertiajs/svelte';

  $: ({ app } = $page.props);

  let getRandMonth = () => {
    let mths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return mths[new Date().getMonth()];
  }
</script>


<style lang="scss">
  @media all {


    sup {
      vertical-align: baseline;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.2;
      color: #020553;
    }

    @media (min-width:1200px) {
      h4 {
        font-size: 1.3rem;
      }
    }

    p {
      font-family: sans-serif;
      color: #433636;
      margin-top: 0;
      margin-bottom: 1.6rem;
      text-align: justify;
    }

    b {
      font-weight: bolder;
    }

    sup {
      position: relative;
      font-size: .75em;
      line-height: 0;
    }

    ::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    .container {
      width: 100%;
      padding-right: var(--bs-gutter-x, 1.5rem);
      padding-left: var(--bs-gutter-x, 1.5rem);
      margin-right: auto;
      margin-left: auto;
    }

    @media (min-width:576px) {
      .container {
        max-width: 540px;
      }
    }

    @media (min-width:768px) {
      .container {
        max-width: 720px;
      }
    }

    @media (min-width:992px) {
      .container {
        max-width: 960px;
      }
    }

    @media (min-width:1200px) {
      .container {
        max-width: 1140px;
      }
    }

    @media (min-width:1400px) {
      .container {
        max-width: 1320px;
      }
    }

    .row {
      --bs-gutter-x: 3rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(-1 * var(--bs-gutter-y));
      margin-right: calc(-.5 * var(--bs-gutter-x));
      margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-top: var(--bs-gutter-y);
    }

    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    .d-inline {
      display: inline !important;
    }

    h4 {
      line-height: 1;
    }

    h4 {
      font-size: 1.5rem;
    }

    @media (min-width:992px) {
      h4 {
        font-size: 1.5rem;
        line-height: 1;
      }
    }

    p {
      font-size: 1.3rem;
      line-height: 1.3;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    @media (max-width:992px) {
      p {
        font-size: 0.9rem;
        line-height: 1.4;
      }
    }

    sup {
      font-size: 55%;
      top: -.75em;
      display: inline-block;
      cursor: pointer;
    }

    *,
    ::after,
    ::before {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
    }

    :focus:not(.focus-visible) {
      outline: 0;
    }

    .container {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (min-width:1199.98px) {
      .container {
        padding-left: 3rem;
        padding-right: 3rem;
        max-width: 100%;
      }
    }

    @media (max-width:767.98px) {
      .page__content--row {
        display: block !important;
      }
    }

    .page__content--col {
      padding-bottom: 16rem;
    }

    .page__content--col h4 {
      margin: 2.8rem 0 2.4rem;
    }

    @media print {

      h4,
      p {
        color: #433636 !important;
      }

      [class*=col-] {
        float: left;
      }

      .col-12 {
        width: 100% !important;
      }

      .col-12 {
        float: left !important;
      }

      .container {
        width: auto;
        min-width: 750px;
      }
    }
  }

  .page__content--row{
    padding-top: 5rem;
  }

 .elementor-element.elementor-element-380d4e6{
   background-image: url('/img/african-american-businessman-writing-down-his-TM5Y3F7.jpg') !important;

   &:before{
    content:"";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
   }
 }
</style>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
      <div class="fullwidth">
          <div data-elementor-type="wp-page" data-elementor-id="3779" class="elementor elementor-3779" data-elementor-settings="[]">
              <div class="elementor-inner">
                  <div class="elementor-section-wrap">
                      <section class="elementor-element elementor-element-380d4e6 elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="380d4e6" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;' style="width: 1770px; left: -262.5px;">
                          <div class="elementor-background-overlay">
                          </div>
                          <div class="elementor-container elementor-column-gap-default">
                              <div class="elementor-row">
                                  <div class="elementor-element elementor-element-50051e6 elementor-column elementor-col-100 elementor-top-column" data-id="50051e6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                      <div class="elementor-column-wrap elementor-element-populated">
                                          <div class="elementor-widget-wrap smooved" style="perspective: 1000px;">
                                              <div class="elementor-element elementor-element-aa6a230 elementor-widget__width-inherit elementor-widget elementor-widget-heading init-smoove smooved" data-id="aa6a230" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":-40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default" style="transition-duration: 1000ms; opacity: 1;">
                                                  <div class="elementor-widget-container">
                                                      <span class="elementor-heading-title elementor-size-default">{app.name} Grants</span>
                                                  </div>
                                              </div>
                                              <div class="elementor-element elementor-element-18a1c93 elementor-widget__width-inherit elementor-widget elementor-widget-heading init-smoove smooved" data-id="18a1c93" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":-40,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default" style="transition-duration: 1000ms; opacity: 1;">
                                                  <div class="elementor-widget-container">
                                                      <h1 class="elementor-heading-title elementor-size-default">Terms of Use</h1>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </div>
              </div>
          </div>
          <div class="comment_disable_clearer">
          </div>
      </div>

      <div class="container page__content">
        <div class="row page__content--row">
          <div class="col-12 page__content--col">

            <p>The following terms of use (“<b>Agreement</b>”) govern your use of {route('app.home')} (the “<b>Site</b>”) and/or any of the services (the “<b>Services</b>”) offered by {app.name}. (“<b>{app.name}</b>”) on and through our Site, including use of any the Site’s information and data, and participation in any public areas which may exist in the Site (the “<b>Public Areas</b>”).</p>



            <p>Please read the requirements of this Agreement carefully. Your use of the Site and\or any of the Services constitutes your agreement to comply with the terms of this Agreement. If you cannot agree to and comply with this Agreement and its requirements, you are expressly prohibited from use of the Site and\or the Services and must exit the Site.</p>



            <h4>Use of Site</h4>



            <p>You may view and use the Site and/or any information contained therein only in accordance with the terms of this Agreement. You may not modify, distribute, publish, license, create derivative works from, transfer or sell any information or content contained on the Site. The Site may be used only as contemplated by this Agreement. You agree to use the Site only for lawful purposes and you acknowledge that your failure to do so may subject you to civil and criminal liability all as determined by applicable law.</p>



            <p>If you wish to use our Services, you will need to provide us with certain information, including information about your identity, finances, and business performance. You will also need to create a user ID and a password to protect your account information. With your permission, we may also access your banking information, obtain a credit report, retrieve bank account data, or collect other third party data about you (collectively, the “Personal Information”).</p>



            <p>You may also invite friends or colleagues to use the Services through our referral program page. When you invite others to join {app.name} by using our referral page, we send them a one-time email for that referral. By using our referral program, you warrant (i) to only send invitations to people you know; (ii) not to misrepresent your relationship with {app.name} or make any warranty or representation on behalf of {app.name}; and (iii) to comply at all times with this Agreement and applicable law.</p>



            <p>As a registered user (“User”) of the Site, you represent and warrant that: (i) you are 18 years of age or older; (ii) the Personal Information is true, accurate, current and complete; (iii) you will maintain and update &nbsp;Personal Information to keep it true, accurate, current, and complete; and; (iv) the &nbsp;Personal Information belongs to you and does not infringe or violate any patents, copyrights, trademarks or other intellectual property, proprietary or privacy rights of any third party; and (v) you own or has obtain the right to all of the intellectual property rights subsisting in the Personal Information provided, and has the right to provide {app.name} the license granted herein to the Information.</p>



            <h4>Changes to Agreement and Additional Rules of Usage</h4>



            <p>{app.name} reserves the right, at any time and without prior notice, to modify, alter or update this Agreement. The date of the most recent revision will appear on this page. Your continued access to the Site and use of the Services by you will constitute your acceptance of any changes or revisions to this Agreement. {app.name} also reserves the right to post, from time to time, additional rules of usage that apply to specific parts of the Site, which may be posted in the relevant parts of the Site, and will be identified. Your continued use of the Site constitutes your agreement to comply with these additional rules.</p>



            <h4>Disclaimer</h4>



            <p>All information (hereinafter, the “Information”) posted on the Site is subject to change or removal without notice. {app.name} may make changes to the materials, products, features and services available at the Site at any time without notice. You may not use the Information for any commercial purpose, including without limitation: (I) distribution (electronically or otherwise); (II) resale; or (III) display, etc.</p>



            <p>Information, Services, and other material available through the Site are furnished “As Is”, “at Your Own Risk” and “As Available”, without any warranty whatsoever. Although {app.name} has attempted to provide accurate Information on the Site, {app.name} assumes no responsibility for the accuracy, merchantability or completeness of the Information, Services or other material at the Site. The Information and all other materials on the Site are provided for general information purposes only and do not constitute professional advice of any kind.</p>



            <p>All other warranties, express or implied, including any warranties of merchantability, fitness for any particular purpose, or non-infringement of intellectual property are specifically excluded and disclaimed. {app.name} does not warrant that the Information will meet user’s requirements and that the operation of the Services will be uninterrupted or error free, or that the Site or the server that makes it available are free of viruses or bugs.</p>



            <p>{app.name}’s obligations with respect to its products and services are governed solely by the agreements under which they are provided and nothing on the Site should be construed to alter such agreements. The materials and Services at the Site may be out of date and {app.name} makes no commitment to update the materials and Services at the Site.</p>



            <h4>Limitation of Liability</h4>



            <p>In no event will {app.name}, its suppliers, or other third parties mentioned at the Site be liable for any damages whatsoever (including, without limitation, those resulting from lost profits, lost savings, lost data, business interruption or other special, consequential or incidental damages) arising out of or relating to the use, inability to use, or the results of use of the Site, any websites or other content linked to the Site, or any materials, information, product or service contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not {app.name} has been advised of the possibility of such loss or damages. If your use of the materials, Information or Services from the Site results in the need for servicing, repair of correction of equipment or data, you assume all costs thereof. For the avoidance of doubt, User is responsible for maintaining and protecting all of the information provided, transmitted, uploaded, imported or made accessible by using the Site. {app.name} will not be liable for any loss or corruption of such information, or for any costs or expenses associated with backing up or restoring any of such information.</p>



            <h4>Privacy Policy</h4>



            <p>In addition to reviewing this Agreement, you should also review our Privacy Policy (“Privacy Policy”), which is incorporated by reference into this Agreement, to better understand how we collect and use personal information. Your use of the Site and the Services constitutes your agreement to its terms and conditions.</p>



            <h4>Intellectual Property</h4>



            <p>The Site, all information and intellectual property referenced on the Site, including, without limitation, patents, copyrights, logos, trademarks and trade secrets are legally owned by {app.name} or third parties. These rights apply to the Information, computer code of the Site, graphical design, products, company names, etc. All contents of the Site are protected by copyright and trademark rights, except as specifically permitted herein.</p>



            <h4>Links to Third-Party Websites and\or Third Party Software Products</h4>



            <p>Links on the Site to third-party websites and\or software products (such as an invoicing or accounting software product that you are using) are provided as a convenience to you. If you use these links or software products, you might leave the Site. Some of the third parties software products may require you to have, and actually be signed-in to, an active user account for such third party software product.</p>



            <p>{app.name} has not reviewed any of these third-party sites and/or the applications and/or software products and does not control and is not responsible and/or liable for any of these sites or their content. Thus, {app.name} does not endorse or make any representations about them, or any information, software or other products or materials found on them, or any results that may be obtained from using them. If you decide to access any of the third-party websites and/or applications and/or software products linked to the Site, you do this entirely at your own risk and {app.name} assumes no liability for such use.</p>



            <h4>Ownership of the Site and Information</h4>



            <p>The Site is expressly owned and operated by {app.name}. Unless otherwise noted, the design and content features on the Site, including information and other materials, illustrations, product layout and design, icons, navigational buttons, images, artwork, graphics, photography, text, data, audio sound, software and the like, as well as the selection, assembly and arrangement thereof (collectively: the “Information”), are owned by {app.name} or its affiliates or are licensed by {app.name} from third parties. The Site, each as a whole and in part, and the Information is protected by copyright, trademark, service mark, trade name, and other intellectual property and other proprietary rights, and all other applicable rights are reserved and User shall abide by those laws. By furnishing Information, {app.name} does not grant any licenses to or transfer any title rights for any copyrights, patents or any other intellectual property rights.</p>



            <h4>Site Availability</h4>



            <p>{app.name} shall not be responsible for any disruption to the Site, regardless of length. {app.name} will not be liable for losses related to User’s inability to access content due to disruptions of the Site. {app.name} shall be excused from its performance of any obligations under this Agreement which are prevented or interfered with due to acts or conditions beyond the reasonable control of {app.name}.</p>



            <h4>Indemnification</h4>



            <p>You agree to indemnify {app.name} and its affiliates, directors, employees, agents, representatives and third party service providers, and to defend and hold each of them harmless, from any and all claims, actions, liabilities, damages, and costs (including attorney’s fees) (collectively, “Claims”) which may arise from your unauthorized use of the Site, Services or Information obtained through use of the Site and/or your breach of this Agreement. {app.name} reserves the right to assume the exclusive control of any such defense of any such Claim.</p>



            <h4>The Security of the Site</h4>



            <p>We maintain physical, electronic, and procedural safeguards and personnel policies, consistent with applicable laws that are designed to guard the Site, our systems and Information provided by our Users. Unfortunately, no data transmission over the Internet can be guaranteed to be absolutely secure. In the event of a breach of the confidentiality or security of your personal information, we will reasonably attempt to notify you as necessary so you can take appropriate protective steps. Unless you indicate otherwise, we may notify you under such circumstances using your most current email address on record with us.</p>



            <h4>Electronic Communications</h4>



            <p>When you visit the Site or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically or posted on the Site satisfy any legal requirement that such communications be in writing if such requirements are applicable.</p>



            <h4>Jurisdiction and Governing Law</h4>



            <p>{app.name} makes no representation that the content, the Information, or the Services offered through the Site are appropriate, available or legal in any particular location. Those who choose to access the content, the Information, and the Services offered through the Site do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>



            <p>Those who access the Site from locations outside California are responsible for compliance with applicable local laws. Any claim relating to the Site or use of the Site or the Services will be governed by and interpreted in accordance with the laws of the State of California, without reference to its conflict-of-laws principles. Any dispute arising out of or related to your use of the Site will be brought in, and you hereby consent to the exclusive jurisdiction and venue in, the competent courts in California. You hereby agree to waive all defenses of lack of personal jurisdiction and forum non-conveniens and agree that process may be served in a manner authorized by applicable law or court rule. If any term or provision of this legal notice is for any reason held to be invalid, such invalidity shall not affect any other term or provision, and this legal notice shall be interpreted as if such term or provision had never been contained in this legal notice.</p>



            <p>The User understands and agrees that, in addition to monetary damages, {app.name} shall be entitled to equitable relief where appropriate upon User’s breach of any portion of this Agreement.</p>



            <h4>Miscellaneous</h4>



            <p>User agrees that no joint venture, partnership, employment or agency relationship exists between User and {app.name} as a result of this Agreement and/or User’s use of the Site. User may not assign any part of its rights and obligations under this Agreement without {app.name}’s prior written consent. No waiver of any obligation or right of either party shall be effective unless in writing, executed by the party against whom it is being enforced. The terms of this Agreement are severable and may be construed to the extent of their enforceability in light of the parties’ mutual intent. The titles and subtitles in this Agreement are used for convenience only and are not to be considered in construing it.</p>



            <h4>General</h4>



            <p>{app.name} may revise these Terms of Use at any time by updating this page on the Site and in such event your continued use of the Site, shall constitute your acceptance of such updated Terms of Use. Accordingly, you should visit this page from time to time to review the then-current Terms of Use because they are binding on you. Certain provisions of these Terms of Use may be superseded by expressly designated legal notices or terms located on particular pages at the Site.</p>



            <p>You may contact {app.name} at {app.address}.</p>



            <p>This Terms of Use Agreement is Effective as of: {getRandMonth()} 1<sup class="d-inline">st</sup>, {new Date().getFullYear()}.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
