<script>
  export let legalName = undefined,
    tradeName = undefined,
    tinType = undefined,
    tin = undefined,
    address = undefined,
    city = undefined,
    zip = undefined,
    phone = undefined,
    email = undefined,
    stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Business Information</h1>

<div class="input-container fluid">

  <div class="w-full">
    <label class="form-input-label" for="bizLegalName">Business Legal Name</label>
    <div class="w-full my-auto">
      <input bind:value="{legalName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="bizLegalName" name="bizname" placeholder="Business Legal Name" type="text">
    </div>
  </div>
  <div class="w-full">
    <label class="form-input-label" for="tradeName">Trade Name (Enter DBA Name if different from legal name)</label>
    <div class="w-full my-auto">
      <input bind:value="{tradeName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="tradeName" name="tradename" placeholder="Trade Name" type="text">
    </div>
  </div>

  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="tinType">Business TIN Type</label>
      <div>
        <div id="tinType" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21 ">
          <select bind:value="{tinType}" id="tinType-options-menu" class="jsx-329458114 flex items-center / w-full h-18 px-4 my-1 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 cursor-default text-neutral-04 bg-neutral-02 border-2 border-gray-secondary">
            <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
              <span class="jsx-329458114 text-left truncate truncate-width">Select One</span>
            </option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">SSN</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">EIN</option>
          </select>
        </div>
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="tin">Business TIN (EIN/SSN)</label>
      <div class="w-full my-auto">
        <input bind:value="{tin}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="tin" name="tin" placeholder="Business TIN" type="text">
      </div>
    </div>
  </div>

  <div class="w-full">
    <label class="form-input-label visible" for="bizaddress">Primary Business Address <span class="text-danger">(Cannot be P. O. Box)</span></label>
    <div class="w-full my-auto">
      <input bind:value="{address}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="bizaddress" name="bizaddress" placeholder="Primary Business Address" type="text">
    </div>
  </div>

  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="cityState">City/State</label>
      <div class="w-full my-auto">
        <input bind:value="{city}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="cityState" name="cityState" placeholder="City / State" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="bizZip">Zip</label>
      <div class="w-full my-auto">
        <input bind:value="{zip}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="bizZip" name="bizZip" placeholder="Zip" type="text">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="bizPhone">Business Phone</label>
      <div class="w-full my-auto">
        <input bind:value="{phone}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="bizPhone" name="bizPhone" placeholder="Business Phone" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="bizEmail">Business Email</label>
      <div class="w-full my-auto">
        <input bind:value="{email}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="bizEmail" name="bizEmail" placeholder="Business Email" type="text">
      </div>
    </div>
  </div>
  <button on:click={() => stage = 10 } type="button" class="jsx-373440444 btn btn-continue ">Back</button>
  <button disabled={! legalName || ! email} on:click={() => stage = 12 } type="button" class="jsx-373440444 btn btn-continue ">Continue</button>
</div>
