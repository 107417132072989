<section class="elementor-element elementor-element-0b176a1 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="0b176a1" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-9c509e5 elementor-column elementor-col-100 elementor-top-column" data-id="9c509e5" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-6bebfb8 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="6bebfb8" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Our blog</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-208355b elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="208355b" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Latest News</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-0348408 elementor-widget elementor-widget-avante-blog-posts" data-id="0348408" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="avante-blog-posts.default">
              <div class="elementor-widget-container">
                <div class="blog-post-content-wrapper layout-grid">
                  <!-- Begin each blog post -->
                  <div class="blog-posts-grid post-3025 post type-post status-publish format-standard has-post-thumbnail hentry category-direction category-entrepreneur tag-direction tag-vision-goal-setting">
                    <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="100" data-minwidth="769" data-offset="-30%">
                      <div class="post-featured-image static">
                        <div class="post-featured-image-hover ">
                          <img src="/img/business-people-working-in-office-UG93BVM-960x550.jpg" class=" smoove disable_tablet" alt="">
                          <a href="#"></a>
                        </div>
                      </div>
                      <div class="post-content-wrapper text-">
                        <div class="post-header">
                          <div class="post-header-title">
                            <h5><a href="#" title="The Biggest Mistake You Can Make When Setting New Goals">The Biggest Mistake You Can Make When Setting New Goals</a></h5>
                          </div>
                        </div>
                        <div class="post-header-wrapper">
                          <div class="post-button-wrapper">
                            <a class="continue-reading" href="#" title="The Biggest Mistake You Can Make When Setting New Goals">Continue Reading<span></span></a>
                          </div>
                          <br class="clear">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End each blog post -->
                  <!-- Begin each blog post -->
                  <div class="blog-posts-grid post-3024 post type-post status-publish format-standard has-post-thumbnail hentry category-direction category-family category-productivity tag-direction tag-vision-goal-setting">
                    <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="200" data-minwidth="769" data-offset="-30%">
                      <div class="post-featured-image static">
                        <div class="post-featured-image-hover ">
                          <img src="/img/young-businesswoman-smiling-at-office-LGQRJY2-960x550.jpg" class=" smoove disable_tablet" alt="">
                          <a href="#"></a>
                        </div>
                      </div>
                      <div class="post-content-wrapper text-">
                        <div class="post-header">
                          <div class="post-header-title">
                            <h5><a href="#" title="4 Rules for a Productive Workday When the Kids Are Home">4 Rules for a Productive Workday When the Kids Are Home</a></h5>
                          </div>
                        </div>
                        <div class="post-header-wrapper">
                          <div class="post-button-wrapper">
                            <a class="continue-reading" href="#" title="4 Rules for a Productive Workday When the Kids Are Home">Continue Reading<span></span></a>
                          </div>
                          <br class="clear">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End each blog post -->
                  <!-- Begin each blog post -->
                  <div class="blog-posts-grid post-3022 post type-post status-publish format-standard has-post-thumbnail hentry category-direction category-life category-productivity tag-direction tag-vision-goal-setting">
                    <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="300" data-minwidth="769" data-offset="-30%">
                      <div class="post-featured-image static">
                        <div class="post-featured-image-hover ">
                          <img src="/img/african-american-businessman-writing-down-his-TM5Y3F7-960x550.jpg" class=" smoove disable_tablet" alt="">
                          <a href="#"></a>
                        </div>
                      </div>
                      <div class="post-content-wrapper text-">
                        <div class="post-header">
                          <div class="post-header-title">
                            <h5><a href="#" title="Why Diversification of Marketing Strategies is Vital in 2019">Why Diversification of Marketing Strategies is Vital in 2019</a></h5>
                          </div>
                        </div>
                        <div class="post-header-wrapper">
                          <div class="post-button-wrapper">
                            <a class="continue-reading" href="#" title="Why Diversification of Marketing Strategies is Vital in 2019">Continue Reading<span></span></a>
                          </div>
                          <br class="clear">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End each blog post -->
                </div>
                <br class="clear">
              </div>
            </div>
            <div class="elementor-element elementor-element-271f117 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image" data-id="271f117" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1494" height="1408" src="/img/home2_morph_bg1.png" class="attachment-full size-full" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
