import { createInertiaApp, router } from '@inertiajs/svelte'
import { getErrorString } from "@public-shared/helpers";

window.swal = require('sweetalert2')
window._ = {
	isString: require('lodash/isString'),
	reduce: require('lodash/reduce'),
	size: require('lodash/size'),
	split: require('lodash/split'),
}
window.Toast = swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
  showCloseButton:true,
  allowEscapeKey:true,
	timer: 5000,
	icon: "success",
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
});

window.ToastLarge = swal.mixin({
	icon: "success",
	title: 'To be implemented!',
	html: 'I will close in <b></b> milliseconds.',
	timer: 5000,
  timerProgressBar: true,
  showConfirmButton: false,
  allowEscapeKey:true,
	willOpen: () => {
		swal.showLoading()
	},
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }

	// willClose: () => {}
})

window.BlockToast = swal.mixin({
	showConfirmButton: true,
	willOpen: () => {
		swal.showLoading()
	},
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  },
  showConfirmButton: false,
	showCloseButton: false,
	allowOutsideClick: false,
	allowEscapeKey: false
});

window.swalPreconfirm = swal.mixin({
	title: 'Are you sure?',
	text: "Implement this when you call the mixin",
	icon: 'question',
	showCloseButton: false,
	allowOutsideClick: () => !swal.isLoading(),
	allowEscapeKey: false,
	showCancelButton: true,
	focusCancel: true,
	cancelButtonColor: '#d33',
	confirmButtonColor: '#3085d6',
	confirmButtonText: 'To be implemented',
	showLoaderOnConfirm: true,
	preConfirm: () => {
		/** Implement this when you call the mixin */
	},
})

router.on('start', (event) => {
	console.log(event);
	jQuery('#page-loader')
		.fadeIn()
})

router.on('progress', (event) => {
  console.log(event);
})

router.on('success', (e) => {
  if (e.detail.page.props.flash.success) {
    ToastLarge.fire( {
      title: "Success",
      html: e.detail.page.props.flash.success,
      icon: "success",
      timer: 5000,
      allowEscapeKey: true
    } );
  } else if (e.detail.page.props.flash.warning) {
    ToastLarge.fire( {
      title: "Oops!",
      html: e.detail.page.props.flash.warning,
      icon: "warning",
      timer: 5000,
      allowEscapeKey: true
    } );
  }
  else {
    swal.close();
  }
  jQuery('#page-loader')
  	.fadeOut()
})

router.on('error', (e) => {
  console.log(`There were errors on your visit`)
  console.log(e)
  jQuery('#page-loader')
  	.fadeOut()
  ToastLarge.fire( {
    title: "Error",
    html: getErrorString( e.detail.errors ),
    icon: "error",
    timer:10000, //milliseconds
    footer:
    	`Our support email: &nbsp;&nbsp;&nbsp; <a target="_blank" href="mailto:support@plannerpinnaclebg.com">support@plannerpinnaclebg.com</a>`,
  } );
})

router.on('invalid', (event) => {
  console.log(`An invalid Inertia response was received.`)

  console.log(event);

  event.preventDefault()
  jQuery('#page-loader')
  	.fadeOut()
  Toast.fire({
    position: 'top',
    title: 'Oops!',
    text: event.detail.response.statusText,
    icon:'error'
  })
})

router.on('exception', (event) => {
  console.log(event);
  console.log(`An unexpected error occurred during an Inertia visit.`)
  console.log(event.detail.error)
})

router.on('finish', (e) => {
  // console.log(e);
})

const app_email = JSON.parse(document.getElementById('app').dataset.page).props.app.email;

//? Make sure Svelte is initialised after our routes are available to the front end
let clearInt = setInterval(() => {
  try {
    route();
    console.log('--------route() initialized-----------');

    //! Initialize SVELTE

    createInertiaApp({
      progress: {
        delay: 250,
        color: '#29d',
        includeCSS: true,
        showSpinner: true,
      },
      resolve: name => {

        let [module, component] = _.split(name, '::');

        return import(
          /*
            // webpackChunkName: "js/[request]"
            webpackChunkName: "js/app-lazy-bundle",
            webpackMode: "lazy-once",
            webpackPrefetch: true,
            webpackPreload: true
          */
           `../../../${module}/Resources/js/Pages/${component}.svelte`
        )

        return require(`../../../${module}/Resources/js/Pages/${component}.svelte`)
      },
      setup({ el, App, props }) {
        if (Object.entries(props.initialPage.props.errors).length) {
          ToastLarge.fire( {
            title: "Error",
            html: getErrorString( props.initialPage.props.errors ),
            icon: "error",
            timer:10000, //milliseconds
            footer:
              `Our support email: &nbsp;&nbsp;&nbsp; <a target="_blank" rel="noopener noreferrer" href="mailto:${app_email}">${app_email}</a>`,
          } );
        }
        else if (props.initialPage.props.flash.success) {
          ToastLarge.fire( {
            title: "Success",
            html: props.initialPage.props.flash.success,
            icon: "success",
            timer: 5000,
            allowEscapeKey: true
          } );
        }
        else if (props.initialPage.props.flash.warning) {
          ToastLarge.fire( {
            title: "Oops!",
            html: props.initialPage.props.flash.warning,
            icon: "warning",
            timer: 5000,
            allowEscapeKey: true
          } );
        }

        new App({ target: el, props })
      },
    })

    clearInterval(clearInt);
  } catch (e) {
    console.log('Error initialising app');
  }
}, 300);
