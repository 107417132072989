<script>
  export let appName;
</script>

<style lang="scss">
  .elementor-top-section{
    &.elementor-section-stretched{
      z-index: 0;
    }
  }
</style>

<section class="elementor-element elementor-element-d593348 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section mt-0" data-id="d593348" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic","shape_divider_top":"triangle"&#125;'>
  <div class="elementor-shape elementor-shape-top" data-negative="false">
    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1000 100" preserveaspectratio="none">
      <path class="elementor-shape-fill" d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"></path>
    </svg>
  </div>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-f3bceaa elementor-column elementor-col-100 elementor-top-column" data-id="f3bceaa" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-caf5a9d elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="caf5a9d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Founder's Highlight</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-39e1900 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="39e1900" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Meet Zhong Shanshan</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-d52a4e4 elementor-widget elementor-widget-avante-slider-synchronized-carousel" data-id="d52a4e4" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="avante-slider-synchronized-carousel.default">
              <div class="elementor-widget-container">
                <div id="tg_synchronized_carousel_slider_d52a4e4" data-pagination="synchronized-carousel-pagination_d52a4e4" class="synchronized-carousel-slider-wrapper sliders-container" data-countslide="3" data-slidetitles='["Technology enabled fitness company","Wearable hardware company","Great food &amp; beverage company"]' data-slideimages='["/img/multiracial-business-people-working-outdoor-in-P39WU43.jpg","/img/photo-of-business-man-wearing-eyeglasses-sitting-PB3SK4U.jpg","/img/business-people-working-in-office-UG93BVM.jpg"]' data-slidebuttontitles='["View Project","View Project","View Project"]' data-slidebuttonurls='["{route('app.about')}","{route('app.about')}","{route('app.contact')}"]'>
                  <ul id="synchronized-carousel-pagination_d52a4e4" class="synchronized-carousel-pagination">
                    <li class="pagination-item"><a href="#/" class="pagination-button"></a></li>
                    <li class="pagination-item"><a href="#/" class="pagination-button"></a></li>
                    <li class="pagination-item"><a href="#/" class="pagination-button"></a></li>
                  </ul>
                </div>
                <br class="clear">
              </div>
            </div>
            <div class="elementor-element elementor-element-4d56274 elementor-absolute elementor-widget elementor-widget-image" data-id="4d56274" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":150,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="672" height="672" src="/img/home3_oval_bg2.png" class="attachment-full size-full" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="elementor-element elementor-element-b967d3d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b967d3d" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-514df55 elementor-column elementor-col-50 elementor-top-column" data-id="514df55" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-60,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-333b8d6 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="333b8d6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-d94e057 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="d94e057" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-815a6cd elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="815a6cd" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-c4abf73 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="c4abf73" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-c54e2d0 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="c54e2d0" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-91bcc72 elementor-widget elementor-widget-heading" data-id="91bcc72" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">"{appName} is on a mission to enable a better financial future for small business owners across China and all over the world.”</span>
              </div>
            </div>
            <div class="elementor-element elementor-element-7e898e5 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="7e898e5" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">Zhong Shanshan</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-3a50dce elementor-column elementor-col-50 elementor-top-column" data-id="3a50dce" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-49e4862 animation elementor-widget elementor-widget-image" data-id="49e4862" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-200,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="683" height="1024" src="/img/Zhong-Shanshan.jpg" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
