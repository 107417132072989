<script>
  export let appName;
</script>

<section class="elementor-element elementor-element-eccd0b9 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="eccd0b9" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-cff40a6 elementor-column elementor-col-50 elementor-top-column" data-id="cff40a6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <section class="elementor-element elementor-element-0821f25 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="0821f25" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-5e28053 elementor-column elementor-col-100 elementor-inner-column" data-id="5e28053" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-989d7d9 elementor-widget elementor-widget-spacer" data-id="989d7d9" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="spacer.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-3eba7ff elementor-widget elementor-widget-text-editor" data-id="3eba7ff" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                {appName} is a small business financial programme  that covers the Asian, European and Northern America parts of the world. {appName} was founded in March 2022 by a few business moguls and investors: American Billionaire Charlie Munger who has worked with Warren Buffet on Berkshire Hathaway, American billionaire Investor Ray Dalio who happens to have served as Co-Chief Investment Officer of the worlds largest hedge funds (Bridgewater Associates) and other billionaire moguls from India, China & Japan. The aim is to provide organizations and small business owners the opportunity of expanding their businesses.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-d21daeb elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="d21daeb" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1494" height="1408" src="/img/home2_morph_bg1.png" class="attachment-full size-full" alt="" srcset="/img/home2_morph_bg1.png 1494w, /img/home2_morph_bg1.png 300w, /img/home2_morph_bg1.png 768w, /img/home2_morph_bg1.png 1024w, /img/home2_morph_bg1.png 440w" sizes="(max-width: 1494px) 100vw, 1494px">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-1089201 elementor-column elementor-col-50 elementor-top-column" data-id="1089201" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <section class="elementor-element elementor-element-df9b61d elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="df9b61d" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-e4f01c5 elementor-column elementor-col-100 elementor-inner-column" data-id="e4f01c5" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-03d9c67 elementor-widget elementor-widget-spacer" data-id="03d9c67" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="spacer.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="elementor-element elementor-element-3bda538 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="3bda538" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-9f8724b elementor-column elementor-col-50 elementor-top-column" data-id="9f8724b" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-b31b3ed elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="b31b3ed" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default"> Charlie Munger</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-2183395 elementor-widget elementor-widget-text-editor" data-id="2183395" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <div class="lqd-lines split-unit lqd-unit-animation-done">
                    Charlie munger was born on the 1st of January 1924. He was educated at Harvard University, California institute of Technology University of Michigan. He lives in Pasadena California with his Wife and 9 children.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-6683823 elementor-column elementor-col-50 elementor-top-column" data-id="6683823" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-57dc750 animation mobile_static elementor-absolute elementor-widget elementor-widget-image" data-id="57dc750" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1024" height="683" src="/img/charlie-munger-1024x683.jpg" class="attachment-large size-large" alt="" srcset="/img/charlie-munger-1024x683.jpg 1024w, /img/charlie-munger-300x200.jpg 300w, /img/charlie-munger-768x512.jpg 768w, /img/charlie-munger-700x466.jpg 700w, /img/charlie-munger-440x293.jpg 440w, /img/charlie-munger.jpg 1440w" sizes="(max-width: 1024px) 100vw, 1024px">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-0f12686 elementor-absolute elementor-widget elementor-widget-image" data-id="0f12686" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":100,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="800" height="792" src="/img/service1_navy_circle.png" class="attachment-full size-full" alt="" srcset="/img/service1_navy_circle.png 800w, /img/service1_navy_circle-300x297.png 300w, /img/service1_navy_circle-150x150.png 150w, /img/service1_navy_circle-768x760.png 768w, /img/service1_navy_circle-440x436.png 440w" sizes="(max-width: 800px) 100vw, 800px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="elementor-element elementor-element-5eddfad elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="5eddfad" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-26601af elementor-column elementor-col-50 elementor-top-column" data-id="26601af" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-e74bd45 animation mobile_static elementor-absolute elementor-widget elementor-widget-image" data-id="e74bd45" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1024" height="683" src="/img/ray-dalio.jpg" class="attachment-large size-large" alt="" srcset="/img/ray-dalio.jpg 1024w, /img/ray-dalio.jpg 300w, /img/ray-dalio.jpg 768w, /img/ray-dalio.jpg 700w, /img/ray-dalio.jpg 440w, /img/ray-dalio.jpg 1440w" sizes="(max-width: 1024px) 100vw, 1024px">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-f930b47 elementor-absolute elementor-widget elementor-widget-image" data-id="f930b47" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1066" height="1058" src="/img/service1_yellow_circle.png" class="attachment-full size-full" alt="" srcset="/img/service1_yellow_circle.png 1066w, /img/service1_yellow_circle.png 300w, /img/service1_yellow_circle.png 1024w, /img/service1_yellow_circle.png 150w, /img/service1_yellow_circle.png 768w, /img/service1_yellow_circle.png 440w" sizes="(max-width: 1066px) 100vw, 1066px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-c213f21 elementor-column elementor-col-50 elementor-top-column" data-id="c213f21" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-3d3bab4 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="3d3bab4" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Ray Dalio</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-670c693 elementor-widget elementor-widget-text-editor" data-id="670c693" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <div class="lqd-lines split-unit lqd-unit-animation-done">
                    Ray Dalio was born on the 8th of August 1949. He was educated at Long Island University, Harvard University. He Lives in Greenwhich, Connecticut with his wife Barbara and 4 children.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="elementor-element elementor-element-e94d863 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="e94d863" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-5d8252e elementor-column elementor-col-50 elementor-top-column" data-id="5d8252e" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-597a27d elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="597a27d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Takashi Kotegawa</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-c1dd5d8 elementor-widget elementor-widget-text-editor" data-id="c1dd5d8" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <div class="lqd-lines split-unit lqd-unit-animation-done">
                    Takashi kotegawa is part of our founders growing businesses in the Asian part of the world. He is one of Japan's most famous day traders who made a fortune in the early 2000s trading stock on the Tokyo stock exchange.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-d65a129 elementor-column elementor-col-50 elementor-top-column" data-id="d65a129" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-465ff0a animation mobile_static elementor-absolute elementor-widget elementor-widget-image" data-id="465ff0a" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1024" height="687" src="/img/Takashi-Kotegawa.jpg" class="attachment-large size-large" alt="" srcset="/img/Takashi-Kotegawa.jpg 1024w, /img/Takashi-Kotegawa.jpg 300w, /img/Takashi-Kotegawa.jpg 768w, /img/Takashi-Kotegawa.jpg 440w, /img/Takashi-Kotegawa.jpg 1440w" sizes="(max-width: 1024px) 100vw, 1024px">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-5b024f9 elementor-absolute elementor-widget elementor-widget-image" data-id="5b024f9" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":100,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="800" height="792" src="/img/service1_navy_circle.png" class="attachment-full size-full" alt="" srcset="/img/service1_navy_circle.png 800w, /img/service1_navy_circle-300x297.png 300w, /img/service1_navy_circle-150x150.png 150w, /img/service1_navy_circle-768x760.png 768w, /img/service1_navy_circle-440x436.png 440w" sizes="(max-width: 800px) 100vw, 800px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
