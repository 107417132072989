<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>


<script>
  import { page, router } from '@inertiajs/svelte';

  $: ({ app } = $page.props);

  let details = {},

  loginUser = () => {
    router.post(route('auth.login'), details);
  }
</script>

<style>
  .j-c-center{
    justify-content: center;
  }

  .elementor-widget.elementor-element-c33a02d,
  .elementor-widget.elementor-element-dc98c8f,
  .elementor-widget.elementor-element-2ef44e6{
    opacity: 1 !important;
  }
</style>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5858" class="elementor elementor-5858" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section class="elementor-element elementor-element-46d3f542 elementor-section-stretched elementor-section-height-min-height elementor-section-items-bottom elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section" data-id="46d3f542" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-57cd0272 elementor-column elementor-col-50 elementor-top-column" data-id="57cd0272" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-2df6a96f elementor-column elementor-col-50 elementor-top-column" data-id="2df6a96f" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-105ec65 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="105ec65" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row j-c-center">
                  <div class="elementor-element elementor-element-65b43c9 elementor-column elementor-col-50 elementor-top-column" data-id="65b43c9" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"false","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-c33a02d elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="c33a02d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"false","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Admin Login</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-dc98c8f elementor-widget elementor-widget-text-editor" data-id="dc98c8f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"false","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                <p class="p1">
                                  Restricted Area
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-2ef44e6 elementor-widget elementor-widget-shortcode" data-id="2ef44e6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"false","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.8,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="shortcode.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-shortcode">
                              <div role="form" class="wpcf7" id="wpcf7-f5-p5858-o1" lang="en-US" dir="ltr">
                                <div class="screen-reader-response">
                                </div>
                                <form class="quform" on:submit|preventDefault|stopPropagation="{loginUser}">

                                  <div class="quform-elements">
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-email">
                                        <input bind:value="{details.email}" id="email" type="text" name="email" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="Email*">
                                      </span>

                                    </div>
                                    <div class="quform-element">

                                      <br>
                                      <span class="wpcf7-form-control-wrap your-password">
                                        <input bind:value="{details.password}" id="password" type="password" name="password" size="40" class="input1" aria-required="true" aria-invalid="false" placeholder="Password*">
                                      </span>

                                    </div>

                                    <!-- Begin Submit button -->
                                    <div class="quform-submit">
                                      <div class="quform-submit-inner">
                                        <button type="submit" class="submit-button"><span>Send</span></button>
                                      </div>
                                    </div>

                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
  <!-- End main content -->
</div>
