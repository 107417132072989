<script>
  export let val = undefined, stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">What industry are you in?</h1>
  <div class="input-container">
    <label class="form-input-label" for="industryOptions">Business Industry</label>
    <div>
      <div id="industryOptions" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21">
        <select name="" id="industry-options-menu" bind:value="{val}" class="jsx-329458114 flex items-center / w-full h-18 px-25 py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 cursor-default text-neutral-04 bg-neutral-02 border-2 border-gray-secondary" required>
          <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
            <span class="jsx-329458114 text-left truncate truncate-width">Select One</span>
          </option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Accommodation and Food Services</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Administrative &amp; Support and Waste Management &amp; Remediation Services</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Agriculture, Forestry, Fishing and Hunting</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Arts, Entertainment, and Recreation</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Construction</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Educational Services</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Finance and Insurance</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Health Care and Social Assistance</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Information</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Management of Companies and Enterprises</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Manufacturing</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Mining</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Other Services (except Public Administration)</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Professional, Scientific, and Technical Services</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Public Administration</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Real Estate Rental and  Leasing</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Retail Trade</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Transportation and Warehousing</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Utilities</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Wholesale Trade</option>
        </select>
      </div>
    </div>
    <button on:click={() => stage = 1 } type="button" class="jsx-373440444 btn btn-continue ">Back</button>
    <button disabled={! val} on:click={() => stage = 3 } type="button" class="jsx-373440444 btn btn-continue ">Continue</button>
</div>
