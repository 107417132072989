<style lang="scss">
  .rounded-bottom-start-45 {
    border-bottom-left-radius: 35%!important;
  }
  .hero-title {
    font-size: 48px !important;

    @media (max-width: 991px) {
      font-size: 36px !important;
    }
  }
</style>

<section class="elementor-element elementor-element-1a6bdba elementor-section-stretched elementor-section-height-min-height elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="1a6bdba" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"book"&#125;'>
  <div class="elementor-shape elementor-shape-bottom" data-negative="false">
    <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1000 100" preserveaspectratio="none">
      <path class="elementor-shape-fill" d="M194,99c186.7,0.7,305-78.3,306-97.2c1,18.9,119.3,97.9,306,97.2c114.3-0.3,194,0.3,194,0.3s0-91.7,0-100c0,0,0,0,0-0 L0,0v99.3C0,99.3,79.7,98.7,194,99z"></path>
    </svg>
  </div>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-739abfb elementor-column elementor-col-50 elementor-top-column" data-id="739abfb" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-ed0cc7e elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="ed0cc7e" data-element_type="widget" data-settings='&#123;"avante_ext_is_infinite":"true","avante_ext_infinite_animation":"if_scale","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="84" height="84" src="/img/red_bubble.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-fc6a816 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="fc6a816" data-element_type="widget" data-settings='&#123;"avante_ext_is_infinite":"true","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_animation":"if_bounce","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="108" height="108" src="/img/navy_bubble.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-ecd9e6f elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="ecd9e6f" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatex":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":-50,"sizes":[]&#125;,"avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="108" height="108" src="/img/navy_bubble.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-9207d49 elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="9207d49" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatex":&#123;"unit":"px","size":300,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":60,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":-360,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="190" height="150" src="/img/navy_triangle.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-10f082a elementor-widget__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="10f082a" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":120,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":-360,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="150" height="120" src="/img/red_triangle2.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-28fd8b3 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-absolute elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-image" data-id="28fd8b3" data-element_type="widget" data-settings='&#123;"avante_ext_is_infinite":"true","avante_ext_infinite_animation":"if_scale","avante_ext_infinite_duration":3,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_infinite_easing":"0.250, 0.250, 0.750, 0.750"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="84" height="84" src="/img/red_bubble.png" class="attachment-large size-large" alt="">
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-7a11b76 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="7a11b76" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Pinnacle Business Grants Program </h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-031f678 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="031f678" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h1 class="elementor-heading-title elementor-size-default hero-title">Empowering Entrepreneurs with Financial Support</h1>
              </div>
            </div>
            <div class="elementor-element elementor-element-f25042f elementor-widget elementor-widget-text-editor" data-id="f25042f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <div class="lqd-lines split-unit lqd-unit-animation-done">
                    <b>Introduction:</b> The Pinnacle Business Grants program is dedicated to fostering innovation and growth within the entrepreneurial community. We understand the challenges faced by aspiring business owners in accessing capital, and our mission is to provide a supportive platform for their success.
                  </div>
                  <div class="lqd-lines split-unit lqd-unit-animation-done mt-10">
                      <b>Grant Offerings - Financial Assistance:</b> We offer grants ranging from $50,000 to $500,000,000, tailored to meet the diverse needs of entrepreneurs at different stages of their business journey.
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-de2ccd2 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="de2ccd2" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <a href="{route('app.contact')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-icon elementor-align-icon-right">
                        <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                      <span class="elementor-button-text">Get In Touch</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-b90f96d elementor-column elementor-col-50 elementor-top-column" data-id="b90f96d" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-fd488f8 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial mobile_static elementor-widget elementor-widget-image" data-id="fd488f8" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.3,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.3,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"true","_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse_depth":&#123;"unit":"px","size":0.2,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
              <div class="elementor-widget-container">
                <div class="elementor-image">
                  <img width="1121" height="1056" src="/img/blur_hero.jpeg" class="attachment-full size-full rounded-bottom-start-45 rounded-top-end-45" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
