<script>
  export let stage,
    street = undefined,
    city = undefined,
    state = undefined,
    zip = undefined;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Where do you reside?</h1>
<div class="input-container fluid">
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="street">Street</label>
      <div>
        <input bind:value="{street}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center tracking-wider border border-gray-secondary" id="street" name="street" placeholder="Street" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="city">City</label>
      <div>
        <input bind:value="{city}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center tracking-wider border border-gray-secondary" id="city" name="city" placeholder="City" type="text">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="state">State</label>
      <div>
        <input bind:value="{state}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center tracking-wider border border-gray-secondary" id="state" name="state" placeholder="State" type="text">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="zipCode">Zip Code</label>
      <div>
        <input bind:value="{zip}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center tracking-wider border border-gray-secondary" id="zipCode" name="zipCode" placeholder="Zip Code" type="text">
      </div>
    </div>
  </div>
  <button on:click={() => stage = 14 }  type="button" class="jsx-373440444 btn btn-continue">Back</button>
  <button disabled={! zip || ! street} on:click={() => stage = 16 }  type="button" class="jsx-373440444 btn btn-continue">Continue</button>
</div>
