<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>

<script>
  import { page } from '@inertiajs/svelte';

  $: ({ app } = $page.props);
</script>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5671" class="elementor elementor-5671" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section class="elementor-element elementor-element-89a6601 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="89a6601" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"triangle","shape_divider_bottom_negative":"yes"&#125;'>
              <div class="elementor-background-overlay">
              </div>
              <div class="elementor-shape elementor-shape-bottom" data-negative="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 1000 100" preserveaspectratio="none">
                  <path class="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
                </svg>
              </div>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-859ab59 elementor-column elementor-col-50 elementor-top-column" data-id="859ab59" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-7c645ce elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="7c645ce" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">A simple, secure way to expand your business.</h1>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-40ae254 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="40ae254" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a href="{route('app.contact')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-icon elementor-align-icon-right">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                                  <span class="elementor-button-text">Get In Touch</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-a0cee8f elementor-column elementor-col-50 elementor-top-column" data-id="a0cee8f" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-e2101b7 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="e2101b7" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-231d024 elementor-column elementor-col-100 elementor-top-column" data-id="231d024" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-6b9e9f5 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="6b9e9f5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">We are global</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-3a8134e elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="3a8134e" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">We are on a mission to help small businesses succeed</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-421e08d elementor-widget elementor-widget-heading" data-id="421e08d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <!-- <span class="elementor-heading-title elementor-size-default">Madison Reed Founder and True Partner Amy Errett discusses the importance of deeply engaged relationships and mentorship.</span> -->
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-c841b1b elementor-absolute elementor-widget elementor-widget-image" data-id="c841b1b" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":150,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="672" height="672" src="/img/home3_oval_bg2.png" class="attachment-full size-full" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-e5702c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="e5702c0" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-f8ec4fc elementor-column elementor-col-100 elementor-top-column" data-id="f8ec4fc" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-f53dfc7 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-counter" data-id="f53dfc7" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="1000" data-to-value="2576" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix"></span>
                              </div>
                              <div class="elementor-counter-title">
                                Cases done
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-6e8026c elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-counter" data-id="6e8026c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="1000" data-to-value="45" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix"></span>
                              </div>
                              <div class="elementor-counter-title">
                                Awards won
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-bee1cd5 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-counter" data-id="bee1cd5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="1000" data-to-value="8" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix"></span>
                              </div>
                              <div class="elementor-counter-title">
                                International offices
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-8159cd6 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-counter" data-id="8159cd6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.6,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="counter.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-counter">
                              <div class="elementor-counter-number-wrapper">
                                <span class="elementor-counter-number-prefix"></span>
                                <span class="elementor-counter-number" data-duration="1000" data-to-value="5" data-from-value="0" data-delimiter=",">0</span>
                                <span class="elementor-counter-number-suffix"></span>
                              </div>
                              <div class="elementor-counter-title">
                                Countries
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-5a1a5e8 elementor-widget__width-inherit elementor-widget elementor-widget-image" data-id="5a1a5e8" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="2000" height="914" src="/img/map.png" class="attachment-full size-full" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-46106b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="46106b7" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-efdc60c elementor-column elementor-col-50 elementor-top-column" data-id="efdc60c" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-942146a animation mobile_static elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" data-id="942146a" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="1024" height="1024" src="/img/business-corporate-colleagues-co-workers-job-PXJLUYE-1024x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-113ea59 elementor-column elementor-col-50 elementor-top-column" data-id="113ea59" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-2e34af8 elementor-widget__width-initial elementor-absolute elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="2e34af8" data-element_type="widget" data-settings='&#123;"avante_ext_is_parallax_mouse":"true","avante_ext_is_parallax_mouse_depth":&#123;"unit":"px","size":0.1,"sizes":[]&#125;,"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Open your mind to the possibilities around you.</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-b967d3d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b967d3d" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-514df55 elementor-column elementor-col-50 elementor-top-column" data-id="514df55" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-60,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-333b8d6 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="333b8d6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-d94e057 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="d94e057" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-815a6cd elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="815a6cd" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-c4abf73 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="c4abf73" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-c54e2d0 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="c54e2d0" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-91bcc72 elementor-widget elementor-widget-heading" data-id="91bcc72" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">“They treated me like family. If I could I would give them 100 Stars!”</span>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-7e898e5 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="7e898e5" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">Jennifer Davis</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-3a50dce elementor-column elementor-col-50 elementor-top-column" data-id="3a50dce" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-49e4862 animation elementor-widget elementor-widget-image" data-id="49e4862" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-200,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="683" height="1024" src="/img/portrait-of-young-businesswoman-standing-in-K8XVHRQ-683x1024.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="elementor-element elementor-element-3d9ccb2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="3d9ccb2" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-0ec76b6 elementor-column elementor-col-50 elementor-top-column" data-id="0ec76b6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-f4178b2 animation elementor-widget elementor-widget-image" data-id="f4178b2" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-200,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="683" height="1024" src="/img/smiling-mature-student-ZQ9XB2R.jpg" class="attachment-large size-large" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-7f0837c elementor-column elementor-col-50 elementor-top-column" data-id="7f0837c" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-50,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-688aee5 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="688aee5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-31a3244 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="31a3244" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-b5b4a7d elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="b5b4a7d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-f40ccee elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="f40ccee" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-43c8e80 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="43c8e80" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-387e4b1 elementor-widget elementor-widget-heading" data-id="387e4b1" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">"Thank you, the team and community for giving me words and tools to make this massive change."</span>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-9f4ab3c elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="9f4ab3c" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">Alice Johnson</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-c51abe4 elementor-section-stretched elementor-section-height-min-height elementor-section-items-top elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section elementor-top-section" data-id="c51abe4" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-0252f8b elementor-column elementor-col-50 elementor-top-column" data-id="0252f8b" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <section class="elementor-element elementor-element-b816faf elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="b816faf" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-1924794 elementor-column elementor-col-50 elementor-inner-column" data-id="1924794" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-9f5b2c7 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" data-id="9f5b2c7" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":&#123;"unit":"px","size":-100,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="722" height="1024" src="/img/colleagues-working-at-a-busy-open-plan-office-PQMUBW3-1-722x1024.jpg" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-b7cf626 elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" data-id="b7cf626" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_disable":"tablet","avante_ext_scrollme_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":-20,"sizes":[]&#125;,"avante_ext_scrollme_smoothness":&#123;"unit":"px","size":30,"sizes":[]&#125;,"avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="1024" height="1024" src="/img/business-people-discussion-planning-growth-PQHBKDT-1024x1024.jpg" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-b27ae33 elementor-column elementor-col-50 elementor-inner-column" data-id="b27ae33" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-7c887af elementor-absolute animation mobile_static elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-image" data-id="7c887af" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"true","avante_ext_scrollme_smoothness":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_scrollme_translatey":&#123;"unit":"px","size":60,"sizes":[]&#125;,"_position":"absolute","avante_ext_scrollme_scalex":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_scaley":&#123;"unit":"px","size":1.2,"sizes":[]&#125;,"avante_ext_scrollme_translatex":&#123;"unit":"px","size":-40,"sizes":[]&#125;,"avante_ext_scrollme_disable":"mobile","avante_ext_scrollme_scalez":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_scrollme_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_scrollme_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="1024" height="1024" src="/img/business-colleagues-conference-teamwork-ideas-PKP7WYL-1024x1024.jpg" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-01c5999 elementor-column elementor-col-50 elementor-top-column" data-id="01c5999" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-663f823 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="663f823" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">We love working with like minded optimists.</h2>
                          </div>
                        </div>
                        <section class="elementor-element elementor-element-8157f3c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="8157f3c" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-2fa2239 elementor-column elementor-col-50 elementor-inner-column" data-id="2fa2239" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-8219325 elementor-widget elementor-widget-heading" data-id="8219325" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-heading-title elementor-size-default">
                                          Address
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-faac78a elementor-widget elementor-widget-heading" data-id="faac78a" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <span class="elementor-heading-title elementor-size-default">{app.address}.</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-b0f1d79 elementor-column elementor-col-50 elementor-inner-column" data-id="b0f1d79" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-eaa4a14 elementor-widget elementor-widget-heading" data-id="eaa4a14" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-heading-title elementor-size-default">
                                          Contact
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-be6f35c elementor-widget elementor-widget-heading" data-id="be6f35c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <span class="elementor-heading-title elementor-size-default">{app.email}<br>{app.working_hours}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div class="elementor-element elementor-element-abbaadf elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="abbaadf" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <a href="{route('app.contact')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-icon elementor-align-icon-right">
                                    <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                                  <span class="elementor-button-text">Get In Touch</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
  <!-- End main content -->
</div>
