<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout;
</script>

<script>
  import { page } from "@inertiajs/svelte";
  import OurDrive from "./Partials/About/OurDrive.svelte";
  import OurMission from "./Partials/About/OurMission.svelte";
  import OurInvestors from "./Partials/About/OurInvestors.svelte";
  import FoundersHighlight from "./Partials/About/FoundersHighlight.svelte";

  $: ({ app } = $page.props);
</script>

<div class="inner">
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5754" class="elementor elementor-5754 elementor-5671" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <OurMission />
            <OurInvestors appName={app.name} />
            <OurDrive />
            <FoundersHighlight appName={app.name} />
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer"></div>
    </div>
  </div>
</div>
