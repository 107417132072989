<script>
  export let appName;
</script>

<section class="elementor-element elementor-element-03a3750 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="03a3750" data-element_type="section">
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-9394a03 elementor-column elementor-col-50 elementor-inner-column" data-id="9394a03" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-5843179 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="5843179" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-513f173 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="513f173" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-6fae2ac elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="6fae2ac" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-7100269 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="7100269" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-b83beb4 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon" data-id="b83beb4" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_scalex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <div class="elementor-icon">
                    <i aria-hidden="true" class="fas fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="elementor-element elementor-element-c712201 elementor-widget elementor-widget-heading" data-id="c712201" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">“It is important to remember that there are no overnight successes.You will need to be dedicated,single-minded,and there is no substitute to hard work.”</span>
              </div>
            </div>
            <div class="elementor-element elementor-element-18bfacc elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="18bfacc" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <span class="elementor-heading-title elementor-size-default">Mukesh Ambani <br> Executive Director</span>
              </div>
            </div>
            <div class="elementor-element elementor-element-45c3c73 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="45c3c73" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
              </div>
            </div>
            <div class="elementor-element elementor-element-163f149 elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon" data-id="163f149" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-wrapper">
                  <!-- <a class="elementor-icon" href="#"><i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-f9ec3b5 elementor-column elementor-col-50 elementor-inner-column" data-id="f9ec3b5" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-4f4e855 elementor-widget elementor-widget-avante-testimonial-card" data-id="4f4e855" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="avante-testimonial-card.default">
              <div class="elementor-widget-container">
                <div class="testimonials-card-wrapper" data-pagination="1" data-beginning-slide="second">
                  <div class="slider">
                    <ul>
                      <li>
                        <div class="testimonial-info">
                          <div class="testimonial-info-title">
                            <h3>One of the easiest online accounting systems we&#039;ve tried</h3>
                            <div class="author">
                              Satish V.N. Gidugu
                              <br>
                              CEO Medi Assist
                            </div>
                          </div>
                          <div class="testimonial-info-img">
                            <img src="/img/mature-businesswoman-wearing-glasses-YT5CU8Q-150x150.jpg" alt="">
                          </div>
                        </div>
                        <div class="testimonial-info-desc">
                          “With the help of {appName} I was able to take my health care from an India based health care to owning centers in London and Singapore!
                        </div>
                      </li>
                      <li>
                        <div class="testimonial-info">
                          <div class="testimonial-info-title">
                            <h3>Indeed this grant was a timely intervention when I needed it.</h3>
                            <div class="author">
                              Estelle W. Hanes
                            </div>
                          </div>
                          <div class="testimonial-info-img">
                            <img src="/img/smiling-mature-man-outdoors-with-mobile-phone-PB28ME5-150x150.jpg" alt="">
                          </div>
                        </div>
                        <div class="testimonial-info-desc">
                          Late last year my dry cleaning business was closing up due to lack of funds to improve the business. Not until I came across {appName} from a friend and I applied for the grant. It has helped me open 2 more laundry rooms across New Mexico.
                        </div>
                      </li>
                      <li>
                        <div class="testimonial-info">
                          <div class="testimonial-info-title">
                            <h3>{appName} is exactly what my business has been lacking.</h3>
                            <div class="author">
                              Meryl M.
                            </div>
                          </div>
                          <div class="testimonial-info-img">
                            <img src="/img/businesswoman-writing-waiting-flight-concept-PA72YZ6-150x150.jpg" alt="">
                          </div>
                        </div>
                        <div class="testimonial-info-desc">
                          I don't know what else to say. {appName} is great. I don't always clop, but when I do, it's because of {appName}. Without {appName}, we would have gone bankrupt by now. I would be lost without {appName}."
                        </div>
                      </li>
                      <li>
                        <div class="testimonial-info">
                          <div class="testimonial-info-title">
                            <h3>{appName} is great</h3>
                            <div class="author">
                              Marti R.
                            </div>
                          </div>
                          <div class="testimonial-info-img">
                            <img src="/img/attractive-business-woman-working-at-his-laptop-P6AMUS6-150x150.jpg" alt="">
                          </div>
                        </div>
                        <div class="testimonial-info-desc">
                          This is simply unbelievable! I was amazed at the quality of {appName}. Keep up the excellent work sirs. Your goals are truly upstanding and it truly makes a difference in the lives of millions.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
