<script>
export let app = {};
</script>


<style>
  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  .flex-top{
    align-content: flex-start !important;
    justify-content: flex-start;
  }

  .link-accent {
    color: #183444;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
  }

  .link-accent:hover {
    text-decoration: underline;
  }

  icon {
    line-height: 0;
    display: inline-block;
  }

  icon>svg.inherit {
    fill: currentColor;
  }

  icon>svg.default {
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
  }

  icon>svg.custom {
    width: 100%;
    height: 100%;
  }

  [_nghost-client-app-c234] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  span[_ngcontent-client-app-c234] {
    flex: 0 1 auto;
    min-width: 0;
  }

  span[_ngcontent-client-app-c234]:not(:first-child) {
    margin-left: 10px;
  }

  span[_ngcontent-client-app-c234]:empty {
    display: none;
  }

  theme-icon {
    display: inline-block;
    line-height: 0;
  }

  @media print {
    theme-icon {
      display: none;
    }
  }

  [_nghost-client-app-c400] {
    display: block;
    margin: 20px 0;
    padding: 0 20px;
  }

  [_nghost-client-app-c400]:last-child {
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  ul[_ngcontent-client-app-c400] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  .icon_and_value[_ngcontent-client-app-c400] li[_ngcontent-client-app-c400] {
    box-sizing: border-box;
    flex: 1 1 100%;
  }

  .icon_and_value[_ngcontent-client-app-c400] li[_ngcontent-client-app-c400]:last-child {
    margin-bottom: 0;
  }

  .icon_and_value[_ngcontent-client-app-c400] li[_ngcontent-client-app-c400] {
    margin-bottom: 8px;
  }

  .icon_and_value[_ngcontent-client-app-c400] li[_ngcontent-client-app-c400] {
    line-height: 0;
  }

  label-with-icon[_ngcontent-client-app-c400] {
    line-height: normal;
  }

  theme-icon[_ngcontent-client-app-c400] {
    color: #666;
  }

  [_nghost-client-app-c207] {
    display: inline-block;
  }

  icon[_ngcontent-client-app-c207] {
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    line-height: 12px;
  }

  a[_ngcontent-client-app-c207] {
    box-sizing: border-box;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: flex-end;
  }

  .external-icon[_ngcontent-client-app-c207] {
    flex: 0 0 auto;
    box-sizing: border-box;
    margin-left: 2px;
    margin-bottom: 2px;
  }

  .hover-text {
    position: relative;
    border-bottom: 1px dotted black;
    cursor: context-menu;
  }

  .hover-text:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: -170%;
  }

  .hover-text:hover:before {
    opacity: 1;
    visibility: visible;
  }

  @media (max-width: 575px) {
    .elementor-3274 .elementor-element.elementor-element-fdf209a,
    .elementor-3274 .elementor-element.elementor-element-22f3534{
      display: none;
    }
  }

  .elementor-3274 .elementor-element.elementor-element-06d1e82{
    padding: 0 20px;
  }
</style>

<div id="footer-wrapper">
    <div data-elementor-type="wp-post" data-elementor-id="3274" class="elementor elementor-3274" data-elementor-settings="[]">
      <div class="elementor-inner">
        <div class="elementor-section-wrap">

          <section class="elementor-element elementor-element-add91cb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-row">

                <div class="elementor-element elementor-element-f9607ea elementor-column elementor-col-75 elementor-top-column" data-element_type="column">
                  <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap">
                      <section class="elementor-element elementor-element-8e007a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div class="elementor-element elementor-element-fdf209a elementor-column elementor-col-50 elementor-inner-column" data-element_type="column">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-6bc7d2f elementor-widget elementor-widget-divider" data-element_type="widget" data-widget_type="divider.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-divider">
                                        <span class="elementor-divider-separator">
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="elementor-element elementor-element-e9d26b3 elementor-column elementor-col-50 elementor-inner-column" data-element_type="column">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-2fe36f5 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">About Us</h3>
                                    </div>
                                  </div>
                                  <div class="elementor-element elementor-element-d34b827 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">

                                        <div _ngcontent-client-app-c316="" class="section-content-wrapper">
                                          <div _ngcontent-client-app-c354="" class="section-content">
                                            <fields-card _ngcontent-client-app-c354="" _nghost-client-app-c400="" class="ng-star-inserted">
                                              <ul _ngcontent-client-app-c400="" class="icon_and_value">
                                                <li _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                  <label-with-icon _ngcontent-client-app-c400="" _nghost-client-app-c234="" class="ng-star-inserted">
                                                    <theme-icon _ngcontent-client-app-c400="" color="inherit" mattooltipshowdelay="500" class="mat-tooltip-trigger inherit default ng-star-inserted" aria-describedby="cdk-describedby-message-1" cdk-describedby-host="0">
                                                      <icon class="ng-star-inserted"><svg viewBox="0 0 24 24" class="default inherit">
                                                          <path d="M12,2C8.1,2,5,5.1,5,9c0,5.2,7,13,7,13s7-7.8,7-13C19,5.1,15.9,2,12,2z M12,11.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S13.4,11.5,12,11.5z"></path>
                                                        </svg></icon>
                                                    </theme-icon> <span _ngcontent-client-app-c234="">
                                                      <field-formatter _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                        {app.address}
                                                      </field-formatter>
                                                    </span>
                                                  </label-with-icon>
                                                </li>
                                                <li _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                  <label-with-icon _ngcontent-client-app-c400="" _nghost-client-app-c234="" class="ng-star-inserted">
                                                    <theme-icon _ngcontent-client-app-c400="" color="inherit" mattooltipshowdelay="500" class="mat-tooltip-trigger inherit default ng-star-inserted" aria-describedby="cdk-describedby-message-2" cdk-describedby-host="0">
                                                      <icon class="ng-star-inserted"><svg viewBox="0 0 24 24" class="default inherit">
                                                          <path d="M16.36,10.91a3.28,3.28,0,1,0-3.27-3.27A3.26,3.26,0,0,0,16.36,10.91Zm-8.72,0A3.28,3.28,0,1,0,4.36,7.64,3.26,3.26,0,0,0,7.64,10.91Zm0,2.18C5.09,13.09,0,14.37,0,16.91v2.73H15.27V16.91C15.27,14.37,10.18,13.09,7.64,13.09Zm8.72,0a10.24,10.24,0,0,0-1,.06,4.59,4.59,0,0,1,2.14,3.76v2.73H24V16.91C24,14.37,18.91,13.09,16.36,13.09Z"></path>
                                                        </svg></icon>
                                                    </theme-icon> <span _ngcontent-client-app-c234="">
                                                      <field-formatter _ngcontent-client-app-c400="" class="ng-star-inserted"> <a class="component--field-formatter field-type-enum link-accent ng-star-inserted" href="#/">101-250</a> </field-formatter>
                                                    </span>
                                                  </label-with-icon>
                                                </li>
                                                <li _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                  <label-with-icon _ngcontent-client-app-c400="" _nghost-client-app-c234="" class="ng-star-inserted">
                                                    <theme-icon _ngcontent-client-app-c400="" color="inherit" mattooltipshowdelay="500" class="mat-tooltip-trigger inherit default ng-star-inserted" aria-describedby="cdk-describedby-message-3" cdk-describedby-host="0">
                                                      <icon class="ng-star-inserted"><svg viewBox="0 0 24 24" class="default inherit">
                                                          <path d="M12.52,10.53c-3-.78-4-1.6-4-2.86,0-1.46,1.35-2.47,3.6-2.47S15.37,6.33,15.45,8H18.4a5.31,5.31,0,0,0-4.28-5.08V0h-4V2.88c-2.59.56-4.67,2.24-4.67,4.81,0,3.08,2.55,4.62,6.27,5.51,3.33.8,4,2,4,3.21,0,.92-.65,2.39-3.6,2.39-2.75,0-3.83-1.23-4-2.8H5.21c.16,2.92,2.35,4.56,4.91,5.11V24h4V21.13c2.6-.49,4.67-2,4.67-4.73C18.79,12.61,15.55,11.32,12.52,10.53Z"></path>
                                                        </svg></icon>
                                                    </theme-icon> <span _ngcontent-client-app-c234="">
                                                      <field-formatter _ngcontent-client-app-c400="" class="ng-star-inserted"> <a class="component--field-formatter field-type-enum link-accent ng-star-inserted" href="#/">Undisclosed</a> </field-formatter>
                                                    </span>
                                                  </label-with-icon>
                                                </li>
                                                <li _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                  <label-with-icon _ngcontent-client-app-c400="" _nghost-client-app-c234="" class="ng-star-inserted">
                                                    <theme-icon _ngcontent-client-app-c400="" color="inherit" mattooltipshowdelay="500" class="mat-tooltip-trigger inherit default ng-star-inserted" aria-describedby="cdk-describedby-message-4" cdk-describedby-host="0">
                                                      <icon class="ng-star-inserted"><svg viewBox="0 0 24 24" class="default inherit">
                                                          <path d="M14.4,6L14,4H5v17h2v-7h5.6l0.4,2h7V6H14.4z"></path>
                                                        </svg></icon>
                                                    </theme-icon> <span _ngcontent-client-app-c234="">
                                                      <field-formatter _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                        <span class="component--field-formatter field-type-enum ng-star-inserted hover-text" data-hover="IPO Status">Private</span>
                                                      </field-formatter>
                                                    </span>
                                                  </label-with-icon>
                                                </li>
                                                <li _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                  <label-with-icon _ngcontent-client-app-c400="" _nghost-client-app-c234="" class="ng-star-inserted">
                                                    <theme-icon _ngcontent-client-app-c400="" color="inherit" mattooltipshowdelay="500" class="mat-tooltip-trigger inherit default ng-star-inserted" aria-describedby="cdk-describedby-message-5" cdk-describedby-host="0">
                                                      <icon class="ng-star-inserted"><svg viewBox="0 0 24 24" class="default inherit">
                                                          <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M11,19.9c-3.9-0.5-7-3.9-7-7.9c0-0.6,0.1-1.2,0.2-1.8L9,15v1c0,1.1,0.9,2,2,2V19.9z M17.9,17.4c-0.3-0.8-1-1.4-1.9-1.4h-1v-3c0-0.6-0.4-1-1-1H8v-2h2c0.6,0,1-0.4,1-1V7h2c1.1,0,2-0.9,2-2V4.6c2.9,1.2,5,4.1,5,7.4C20,14.1,19.2,16,17.9,17.4z"></path>
                                                        </svg></icon>
                                                    </theme-icon> <span _ngcontent-client-app-c234="">
                                                      <field-formatter _ngcontent-client-app-c400="" class="ng-star-inserted">
                                                        <link-formatter _nghost-client-app-c207="" class="ng-star-inserted"><a _ngcontent-client-app-c207="" rel="nofollow noopener noreferrer" class="component--field-formatter link-accent ng-star-inserted" href="{app.url}" target="_blank" title="{app.url}" aria-label="{app.url}"> {app.url} <icon _ngcontent-client-app-c207="" size="custom" key="icon_new_tab" color="inherit" class="external-icon ng-star-inserted"><svg viewBox="0 0 24 24" class="custom inherit">
                                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                                                              </svg></icon> </a> </link-formatter>
                                                      </field-formatter>
                                                    </span>
                                                  </label-with-icon>
                                                </li>
                                              </ul>
                                            </fields-card>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                <div class="elementor-element elementor-element-6d133ed elementor-column elementor-col-25 elementor-top-column" data-element_type="column">
                  <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap flex-top">
                      <section class="elementor-element elementor-element-ea87079 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                          <div class="elementor-row">
                            <div class="elementor-element elementor-element-22f3534 elementor-column elementor-col-50 elementor-inner-column" data-element_type="column">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-34630bf elementor-widget elementor-widget-divider" data-element_type="widget" data-widget_type="divider.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-divider">
                                        <span class="elementor-divider-separator">
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="elementor-element elementor-element-d8721d9 elementor-column elementor-col-50 elementor-inner-column" data-element_type="column">
                              <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-cb60404 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                      <h3 class="elementor-heading-title elementor-size-default">Useful Links</h3>
                                      <br>
                                    </div>
                                  </div>
                                  <div class="elementor-element elementor-element-06d1e82 elementor-widget elementor-widget-text-editor" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                      <div class="elementor-text-editor elementor-clearfix">
                                        <a href="{route('app.home')}">Home</a> <br />
                                        <a href="{route('app.privacy')}">Privacy Policy</a> <br />
                                        <a href="{route('app.terms')}">Terms of Use</a> <br />
                                        <a href="{route('app.careers')}">Careers</a> <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <section class="elementor-element elementor-element-e77192c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div class="elementor-row">
                <div class="elementor-element elementor-element-42a9251 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                  <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap">
                      <div class="elementor-element elementor-element-5b308e6 elementor-shape-circle elementor-widget__width-auto elementor-widget elementor-widget-social-icons" data-element_type="widget" data-widget_type="social-icons.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-social-icons-wrapper">
                            <a href="#/" class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-10f63aa" target="_blank">
                              <span class="elementor-screen-only">Twitter</span>
                              <i class="fab fa-twitter"></i></a>
                            <a href="#/" class="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-a9fc5ce" target="_blank">
                              <span class="elementor-screen-only">Linkedin</span>
                              <i class="fab fa-linkedin"></i></a>
                            <a href="#/" class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-fbe6ab2" target="_blank">
                              <span class="elementor-screen-only">Instagram</span>
                              <i class="fab fa-instagram"></i></a>
                          </div>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-01821a2 elementor-widget elementor-widget-heading" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <span class="elementor-heading-title elementor-size-default">Copyright {new Date().getFullYear() } by {app.name}. All Rights Reserved.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
