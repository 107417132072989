<script>
  export let val = undefined, stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">What are you seeking funding for?</h1>
<div class="input-container">
  <label class="form-input-label" for="grantPurpose">Grant Purpose</label>
  <div>
    <div id="grantPurpose" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21">
      <select bind:value="{val}" id="grantPurpose-options-menu" class="jsx-329458114 flex items-center / w-full h-18 px-4 py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 cursor-default text-neutral-04 bg-neutral-02 border-2 border-gray-secondary" required>
        <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
          <span class="jsx-329458114 text-left truncate truncate-width">Select One</span>
        </option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Expansion</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Equipment Purchase</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Finance Accounts Receivable</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Inventory</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Marketing / Sales</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Payroll</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Purchase Vehicle(s)</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Remodel Building</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Refinance Debt</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Working Capital / Cash Flow</option>
        <option class="jsx-329458114 block min-h-9 w-full bg-white">Other</option>
      </select>
    </div>
  </div>
  <button on:click={() => stage = 2 } type="button" class="jsx-373440444 btn btn-continue  ">Back</button>
  <button disabled={!val} on:click={() => stage = 4 } type="button" class="jsx-373440444 btn btn-continue  ">Continue</button>
</div>
