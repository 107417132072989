<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>


<script>
  import { page } from '@inertiajs/svelte';

  $: ({ app } = $page.props);
</script>

<style>
  .elementor-5754 .elementor-element.elementor-element-e9dfe5f .elementor-icon-list-text{
    color: #000;
  }

  .elementor-4074 .elementor-element.elementor-element-dffa352>.elementor-element-populated {
    margin: 40px 0 0 -100px;
    padding-left: 0;
  }

  @media (max-width: 767px){
    .elementor-4074 .elementor-element.elementor-element-dffa352>.elementor-element-populated {
        margin: 20px 17px 0 20px;
    }
  }

  .elementor-4074 .elementor-element.elementor-element-48a942d .elementor-heading-title {
    color: #062383;
    font-family: Gilroy-ExtraBold,Sans-serif;
    font-size: 45px;
    line-height: 1.2em;
}

.elementor-4074 .elementor-element.elementor-element-155d56c .elementor-heading-title{
  color: #808291;
  font-family: Karla,Sans-serif;
  font-size: 16px;
  line-height: 1.7em;
}

.elementor-4074 .elementor-element.elementor-element-14bb544 .elementor-heading-title{
  color: #ec812a;
  font-family: Gilroy-ExtraBold,Sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.elementor-4074 .elementor-element.elementor-element-82621aa .elementor-icon{
  background-color: #ec812a;
}

.elementor-tab-title a{
  color: #062383 !important;
}

.elementor-5459 .elementor-element.elementor-element-99b2f2d.elementor-widget-heading .elementor-heading-title{
  /* color: #fff; */
}
</style>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="5459" class="elementor elementor-5459" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap elementor-4074 elementor-5754">

            <section class="elementor-element elementor-element-c690171 elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="c690171" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic","shape_divider_bottom":"clouds","shape_divider_bottom_negative":"yes"&#125;'>
              <div class="elementor-shape elementor-shape-bottom" data-negative="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 283.5 27.8" preserveaspectratio="xMidYMax slice">
                  <path class="elementor-shape-fill" d="M265.8 3.5c-10.9 0-15.9 6.2-15.9 6.2s-3.6-3.5-9.2-.9c-9.1 4.1-4.4 13.4-4.4 13.4s-1.2.2-1.9.9c-.6.7-.5 1.9-.5 1.9s-1-.5-2.3-.2c-1.3.3-1.6 1.4-1.6 1.4s.4-3.4-1.5-5c-3.9-3.4-8.3-.2-8.3-.2s-.6-.7-.9-.9c-.4-.2-1.2-.2-1.2-.2s-4.4-3.6-11.5-2.6-10.4 7.9-10.4 7.9-.5-3.3-3.9-4.9c-4.8-2.4-7.4 0-7.4 0s2.4-4.1-1.9-6.4-6.2 1.2-6.2 1.2-.9-.5-2.1-.5-2.3 1.1-2.3 1.1.1-.7-1.1-1.1c-1.2-.4-2 0-2 0s3.6-6.8-3.5-8.9c-6-1.8-7.9 2.6-8.4 4-.1-.3-.4-.7-.9-1.1-1-.7-1.3-.5-1.3-.5s1-4-1.7-5.2c-2.7-1.2-4.2 1.1-4.2 1.1s-3.1-1-5.7 1.4-2.1 5.5-2.1 5.5-.9 0-2.1.7-1.4 1.7-1.4 1.7-1.7-1.2-4.3-1.2c-2.6 0-4.5 1.2-4.5 1.2s-.7-1.5-2.8-2.4c-2.1-.9-4 0-4 0s2.6-5.9-4.7-9c-7.3-3.1-12.6 3.3-12.6 3.3s-.9 0-1.9.2c-.9.2-1.5.9-1.5.9S99.4 3 94.9 3.9c-4.5.9-5.7 5.7-5.7 5.7s-2.8-5-12.3-3.9-11.1 6-11.1 6-1.2-1.4-4-.7c-.8.2-1.3.5-1.8.9-.9-2.1-2.7-4.9-6.2-4.4-3.2.4-4 2.2-4 2.2s-.5-.7-1.2-.7h-1.4s-.5-.9-1.7-1.4-2.4 0-2.4 0-2.4-1.2-4.7 0-3.1 4.1-3.1 4.1-1.7-1.4-3.6-.7c-1.9.7-1.9 2.8-1.9 2.8s-.5-.5-1.7-.2c-1.2.2-1.4.7-1.4.7s-.7-2.3-2.8-2.8c-2.1-.5-4.3.2-4.3.2s-1.7-5-11.1-6c-3.8-.4-6.6.2-8.5 1v21.2h283.5V11.1c-.9.2-1.6.4-1.6.4s-5.2-8-16.1-8z"></path>
                </svg>
              </div>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-32269fe elementor-column elementor-col-50 elementor-top-column" data-id="32269fe" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-99b2f2d elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="99b2f2d" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">We Take Care<br>of your business Dreams</h1>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-d30420f elementor-widget elementor-widget-text-editor" data-id="d30420f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <p>
                                <!-- Madison Reed Founder and True Partner Amy Errett discusses the importance of deeply engaged relationships and mentorship. -->
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-3807ef5 elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon" data-id="3807ef5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;}' data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <a class="elementor-icon" href="{route('app.about')}">
                                <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></a>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-546c42e elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="546c42e" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default"><a href="{route('app.about')}">learn more about us</a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-8ec0ba0 elementor-column elementor-col-50 elementor-top-column" data-id="8ec0ba0" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap">
                      <div class="elementor-widget-wrap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-4659e7e elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="4659e7e" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;'>
              <div class="elementor-background-overlay">
              </div>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-c713225 elementor-column elementor-col-33 elementor-top-column" data-id="c713225" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-c33ebc8 elementor-widget elementor-widget-heading" data-id="c33ebc8" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Why Choose<br>
                              {app.name} for your company?</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-f71ecde elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="f71ecde" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-divider" style={`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ec812a' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e");`}>
                              <span class="elementor-divider-separator">
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-fccc099 elementor-widget elementor-widget-text-editor" data-id="fccc099" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-text-editor elementor-clearfix">
                              <div class="lqd-lines split-unit lqd-unit-animation-done">
                                At {app.name} we are a non profit financial programme our primary aim is to expand businesses all over the world.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-9c515b2 elementor-column elementor-col-66 elementor-top-column" data-id="9c515b2" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <section class="elementor-element elementor-element-7eae843 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="7eae843" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-c9c01e5 elementor-column elementor-col-33 elementor-inner-column" data-id="c9c01e5" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-23b9af2 elementor-widget elementor-widget-image" data-id="23b9af2" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="184" height="116" src="/img/home9_icon1.png" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-37517db elementor-widget elementor-widget-heading" data-id="37517db" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">National top 50 consulting firms</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-690b9af elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="690b9af" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider" style={`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ec812a' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e");`}>
                                          <span class="elementor-divider-separator">
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-ec9d9d6 elementor-widget elementor-widget-text-editor" data-id="ec9d9d6" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <div class="lqd-lines split-unit lqd-unit-animation-done">
                                            <p class="p1">
                                              Our founders and Executive Directors are consistently ranked among the top consulting investors across the globe.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-ac08fa5 elementor-column elementor-col-33 elementor-inner-column" data-id="ac08fa5" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-bb4027f elementor-widget elementor-widget-image" data-id="bb4027f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="152" height="156" src="/img/home9_icon2.png" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-745406c elementor-widget elementor-widget-heading" data-id="745406c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">99.9% client satisfaction</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-00d5df9 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="00d5df9" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider" style={`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ec812a' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e");`}>
                                          <span class="elementor-divider-separator">
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-c692576 elementor-widget elementor-widget-text-editor" data-id="c692576" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <div class="lqd-lines split-unit lqd-unit-animation-done">
                                            <p class="p1">
                                              Dedicated to Providing personal attention and good fast services to all our clients around the globe.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-d867f63 elementor-column elementor-col-33 elementor-inner-column" data-id="d867f63" data-element_type="column" data-settings='&#123;"background_background":"classic","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-3627fc5 elementor-widget elementor-widget-image" data-id="3627fc5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":500,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.7,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="100" height="124" src="/img/home9_icon3.png" class="attachment-large size-large" alt="">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4957dd4 elementor-widget elementor-widget-heading" data-id="4957dd4" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Over 1,400+ Business Cases</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-6eebba2 elementor-widget-divider--separator-type-pattern elementor-widget elementor-widget-divider" data-id="6eebba2" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="divider.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-divider" style={`--divider-pattern-url: url(" data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' preserveaspectratio='none' overflow='visible' height='100%' viewbox='0 0 24 24' stroke='%23ec812a' stroke-width='1' fill='none' stroke-linecap='square' stroke-miterlimit='10' %3e%3cpolyline points='0,18 12,6 24,18 ' /%3e%3c/svg%3e");`}>
                                          <span class="elementor-divider-separator">
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-8e986d5 elementor-widget elementor-widget-text-editor" data-id="8e986d5" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <div class="lqd-lines split-unit lqd-unit-animation-done">
                                            <p class="p1">
                                              We take pride fighting and making sure that  the small business owners are heard in the financial world around the globe.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-df31f43 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="df31f43" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-87f09a8 elementor-column elementor-col-100 elementor-top-column" data-id="87f09a8" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-c60229b elementor-widget__width-inherit elementor-absolute elementor-widget elementor-widget-image" data-id="c60229b" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <img width="747" height="704" src="/img/hom3_morph_bg2.png" class="attachment-full size-full" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-26845b4 elementor-widget__width-inherit elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="26845b4" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">We are successful business entrepreneurs who love to <br>
                             watch businesses grow.</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-64d9982 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="64d9982" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;'>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-2c2aa61 elementor-column elementor-col-100 elementor-top-column" data-id="2c2aa61" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">

                        <section class="elementor-element elementor-element-82e4d99 elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="82e4d99" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div class="elementor-element elementor-element-5bc299e elementor-column elementor-col-50 elementor-inner-column" data-id="5bc299e" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-3e9c73c elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="3e9c73c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Application Steps</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-a380d98 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="a380d98" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">How do I apply?</h2>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-e2ea58f elementor-widget elementor-widget-accordion" data-id="e2ea58f" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_scalex":&#123;"unit":"px","size":0.9,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":0.9,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":60,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_rotatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="accordion.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-accordion" role="tablist">
                                          <div class="elementor-accordion-item">
                                            <!-- svelte-ignore a11y-no-noninteractive-element-to-interactive-role -->
                                            <h6 id="elementor-tab-title-2371" class="elementor-tab-title" data-tab="1" role="tab" aria-controls="elementor-tab-content-2371">
                                              <span class="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
                                                <span class="elementor-accordion-icon-closed"><i class="fas fa-angle-down"></i></span>
                                                <span class="elementor-accordion-icon-opened"><i class="fas fa-angle-up"></i></span>
                                              </span>
                                              <a href="">Apply in minutes</a>
                                            </h6>
                                            <div id="elementor-tab-content-2371" class="elementor-tab-content elementor-clearfix" data-tab="1" role="tabpanel" aria-labelledby="elementor-tab-title-2371">
                                              <p class="p1">
                                                Apply in minutes: Our simple, Online Application takes just minutes to complete. See if you're approved today at absolutely no cost.
                                              </p>
                                            </div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <!-- svelte-ignore a11y-no-noninteractive-element-to-interactive-role -->
                                            <h6 id="elementor-tab-title-2372" class="elementor-tab-title" data-tab="2" role="tab" aria-controls="elementor-tab-content-2372">
                                              <span class="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
                                                <span class="elementor-accordion-icon-closed"><i class="fas fa-angle-down"></i></span>
                                                <span class="elementor-accordion-icon-opened"><i class="fas fa-angle-up"></i></span>
                                              </span>
                                              <a href="">Dedicated Account Manager</a>
                                            </h6>
                                            <div id="elementor-tab-content-2372" class="elementor-tab-content elementor-clearfix" data-tab="2" role="tabpanel" aria-labelledby="elementor-tab-title-2372">
                                              <p class="p1">
                                                Once you've taken a moment to upload your documents, a dedicated Account Manager will be in touch to provide 1-on-1 support for all of your funding needs via any of our social media platforms
                                              </p>
                                            </div>
                                          </div>
                                          <div class="elementor-accordion-item">
                                            <!-- svelte-ignore a11y-no-noninteractive-element-to-interactive-role -->
                                            <h6 id="elementor-tab-title-2373" class="elementor-tab-title" data-tab="3" role="tab" aria-controls="elementor-tab-content-2373">
                                              <span class="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
                                                <span class="elementor-accordion-icon-closed"><i class="fas fa-angle-down"></i></span>
                                                <span class="elementor-accordion-icon-opened"><i class="fas fa-angle-up"></i></span>
                                              </span>
                                              <a href="">Get Approved & Funded</a>
                                            </h6>
                                            <div id="elementor-tab-content-2373" class="elementor-tab-content elementor-clearfix" data-tab="3" role="tabpanel" aria-labelledby="elementor-tab-title-2373">
                                              <p class="p1">
                                                Once you’re Approved and you have accepted our offer, we’ll finalize the details provided and carry out our verification processes. The duration of this process varies according to your country and business category.  Once completed, you will have the funds sent to you in as little as 48 Hours.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="elementor-element elementor-element-4ea6f54 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="4ea6f54" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="1494" height="1408" src="/img/home2_morph_bg1.png" class="attachment-full size-full" alt="">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="elementor-element elementor-element-4ffd281 elementor-column elementor-col-50 elementor-inner-column" data-id="4ffd281" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                                <div class="elementor-column-wrap elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div class="elementor-element elementor-element-b8c0a82 elementor-widget__width-initial elementor-absolute animation mobile_static elementor-widget-tablet__width-initial elementor-widget elementor-widget-image" data-id="b8c0a82" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-image">
                                          <img width="1440" height="960" src="/img/biz-grant-cards.jpg" class="attachment-full size-full" alt="">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-fb8d1c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="fb8d1c3" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-8601bb1 elementor-column elementor-col-33 elementor-top-column" data-id="8601bb1" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-e0e64d3 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="e0e64d3" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <!-- <h2 class="elementor-heading-title elementor-size-default">About us</h2> -->
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-aab0399 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="aab0399" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <!-- <h2 class="elementor-heading-title elementor-size-default">How do I apply?</h2> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-7bc3d66 elementor-column elementor-col elementor-top-column" data-id="7bc3d66" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-118d518 elementor-widget elementor-widget-heading" data-id="118d518" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">Grants are originated by Nongfu spring beverage company license No (HKG:9633),Bridgewater Associates License No(CRD #105129/SEC#:801-35875),Federal Barc Union Bank</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-509d473 elementor-element-e9dfe5f elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"  data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;'>
              <div class="elementor-background-overlay">
              </div>
              <div class="elementor-container elementor-column-gap-default">
                  <div class="elementor-row">
                      <div class="elementor-element elementor-element-174d4c6 elementor-column elementor-col-66 elementor-top-column"  data-element_type="column" >
                          <div class="elementor-column-wrap elementor-element-populated">
                              <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-e10c44d animation elementor-widget__width-auto elementor-widget-tablet__width-inherit elementor-widget elementor-widget-image"  data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                                      <div class="elementor-widget-container">
                                          <div class="elementor-image">
                                              <img width="1260" height="840" src="/img/smiling-happy-colleagues-working-at-company-UMG8QDX.jpg" class="attachment-full size-full" alt="" >
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="elementor-element elementor-element-dffa352 elementor-column elementor-col-33 elementor-top-column"  data-element_type="column" >
                          <div class="elementor-column-wrap elementor-element-populated">
                              <div class="elementor-widget-wrap">
                                  <div class="elementor-element elementor-element-48a942d elementor-widget__width-auto elementor-widget elementor-widget-heading"  data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                          <h2 class="elementor-heading-title elementor-size-default">Our Business Process</h2>
                                      </div>
                                  </div>
                                  <div class="elementor-element elementor-element-155d56c elementor-widget elementor-widget-heading"  data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                          <span class="elementor-heading-title elementor-size-default">Planner pinnacle business grants is a world wide financial programme to enable both young and old business owners expand their line of business. With strategic global partnerships we aim to boost 500 small and medium-sized businesses globally every year. </span>
                                      </div>
                                      <div class="elementor-widget-container">
                                          <span class="elementor-heading-title elementor-size-default">We are in partnership with Federal Barc Union bank (London). We also have partnership with Reliance Industries Ltd (India) which is co-owned by one of our very own, MUKESH AMBANI </span>
                                      </div>
                                      <div class="elementor-widget-container">
                                          <span class="elementor-heading-title elementor-size-default">You can get a grant of up to $5,000,000 in 5 business days. A simple,Secure way to Expand your business </span>
                                      </div>
                                      <div class="elementor-widget-container">
                                        <ul class="elementor-icon-list-items">
                                            <li class="elementor-icon-list-item">
                                              <span class="elementor-icon-list-icon"><i aria-hidden="true" class="fas fa-chevron-right"></i></span>
                                              <span class="elementor-icon-list-text">Apply with every impact to your credit score (610 above)</span>
                                            </li>
                                            <li class="elementor-icon-list-item">
                                              <span class="elementor-icon-list-icon"><i aria-hidden="true" class="fas fa-chevron-right"></i></span>
                                              <span class="elementor-icon-list-text">Funding up to $5,000,000 in as little as 5 business days</span>
                                            </li>
                                            <li class="elementor-icon-list-item">
                                              <span class="elementor-icon-list-icon"><i aria-hidden="true" class="fas fa-chevron-right"></i></span>
                                              <span class="elementor-icon-list-text">No payment penalties </span>
                                            </li>
                                        </ul>
                                      </div>
                                  </div>
                                  <div class="elementor-element elementor-element-82621aa elementor-view-stacked elementor-widget__width-auto elementor-shape-circle elementor-widget elementor-widget-icon"  data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="icon.default">
                                      <div class="elementor-widget-container">
                                          <div class="elementor-icon-wrapper">
                                              <a class="elementor-icon" href="{route('app.about')}">
                                              <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></a>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="elementor-element elementor-element-14bb544 elementor-widget__width-auto elementor-widget elementor-widget-heading"  data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                                      <div class="elementor-widget-container">
                                          <span class="elementor-heading-title elementor-size-default"><a href="{route('app.about')}">learn more about how we work</a></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </section>

            <section class="elementor-element elementor-element-3bc9fa7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="3bc9fa7" data-element_type="section">
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-a2641c0 elementor-column elementor-col-100 elementor-top-column" data-id="a2641c0" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-4d5e55a elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="4d5e55a" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Latest News</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-311347b elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="311347b" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
                          <div class="elementor-widget-container">
                            <h2 class="elementor-heading-title elementor-size-default">Join over 1,000 subscribers and receive our quarterly newsletter.</h2>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-aea18da elementor-widget elementor-widget-avante-blog-posts" data-id="aea18da" data-element_type="widget" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="avante-blog-posts.default">
                          <div class="elementor-widget-container">
                            <div class="blog-post-content-wrapper layout-grid">
                              <!-- Begin each blog post -->
                              <div class="blog-posts-grid post-190 post type-post status-publish format-standard has-post-thumbnail hentry category-career category-family category-productivity tag-direction tag-vision-goal-setting">
                                <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="100" data-minwidth="769" data-offset="-30%">
                                  <div class="post-featured-image static">
                                    <div class="post-featured-image-hover ">
                                      <img src="/img/rawpixel-783345-unsplash-960x550.jpg" class=" smoove disable_tablet" alt="">
                                      <a href="#"></a>
                                    </div>
                                  </div>
                                  <div class="post-content-wrapper text-">
                                    <div class="post-header">
                                      <div class="post-header-title">
                                        <h5><a href="#" title="Why Diversification of Marketing Strategies is Vital in 2022">Why Diversification of Marketing Strategies is Vital in 2022</a></h5>
                                      </div>
                                    </div>
                                    <div class="post-header-wrapper">
                                      <div class="post-button-wrapper">
                                        <a class="continue-reading" href="#" title="Why Diversification of Marketing Strategies is Vital in 2022">Continue Reading<span></span></a>
                                        <div class="post-attribute">
                                          <a href="#" title="Why Diversification of Marketing Strategies is Vital in 2022">April 3, 2022</a>
                                        </div>
                                      </div>
                                      <br class="clear">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- End each blog post -->
                              <!-- Begin each blog post -->
                              <div class="blog-posts-grid post-187 post type-post status-publish format-standard has-post-thumbnail hentry category-career category-family category-life category-productivity tag-direction tag-vision-goal-setting">
                                <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="200" data-minwidth="769" data-offset="-30%">
                                  <div class="post-featured-image static">
                                    <div class="post-featured-image-hover ">
                                      <img src="/img/annie-spratt-wgivdx9dBdQ-unsplash-960x550.jpg" class=" smoove disable_tablet" alt="">
                                      <a href="#"></a>
                                    </div>
                                  </div>
                                  <div class="post-content-wrapper text-">
                                    <div class="post-header">
                                      <div class="post-header-title">
                                        <h5><a href="#" title="What Your Audience Wants From Your Next Speaking Engagement">What Your Audience Wants From Your Next Speaking Engagement</a></h5>
                                      </div>
                                    </div>
                                    <div class="post-header-wrapper">
                                      <div class="post-button-wrapper">
                                        <a class="continue-reading" href="#" title="What Your Audience Wants From Your Next Speaking Engagement">Continue Reading<span></span></a>
                                        <div class="post-attribute">
                                          <a href="#" title="What Your Audience Wants From Your Next Speaking Engagement">April 2, 2022</a>
                                        </div>
                                      </div>
                                      <br class="clear">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- End each blog post -->
                              <!-- Begin each blog post -->
                              <div class="blog-posts-grid post-3024 post type-post status-publish format-standard has-post-thumbnail hentry category-direction category-family category-productivity tag-direction tag-vision-goal-setting">
                                <div class="post-wrapper smoove disable_tablet" data-move-y="450px" data-rotate-x="24deg" data-delay="300" data-minwidth="769" data-offset="-30%">
                                  <div class="post-featured-image static">
                                    <div class="post-featured-image-hover ">
                                      <img src="/img/young-businesswoman-smiling-at-office-LGQRJY2-960x550.jpg" class=" smoove disable_tablet" alt="">
                                      <a href="#"></a>
                                    </div>
                                  </div>
                                  <div class="post-content-wrapper text-">
                                    <div class="post-header">
                                      <div class="post-header-title">
                                        <h5><a href="#" title="4 Rules for a Productive Workday When the Kids Are Home">4 Rules for a Productive Workday When the Kids Are Home</a></h5>
                                      </div>
                                    </div>
                                    <div class="post-header-wrapper">
                                      <div class="post-button-wrapper">
                                        <a class="continue-reading" href="#" title="4 Rules for a Productive Workday When the Kids Are Home">Continue Reading<span></span></a>
                                        <div class="post-attribute">
                                          <a href="#" title="4 Rules for a Productive Workday When the Kids Are Home">April 2, 2022</a>
                                        </div>
                                      </div>
                                      <br class="clear">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- End each blog post -->
                            </div>
                            <br class="clear">
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-1a2a90a elementor-widget__width-inherit elementor-absolute elementor-widget elementor-widget-image" data-id="1a2a90a" data-element_type="widget" data-settings='&#123;"_position":"absolute","avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-image">
                              <!-- <img width="1494" height="1408" src="/img/home2_morph_bg1.png" class="attachment-full size-full" alt=""> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>
  </div>
  <!-- End main content -->
</div>
