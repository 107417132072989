<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>

<script>
  import { page } from '@inertiajs/svelte';

  $: ({ app } = $page.props);
</script>

<style lang="scss">
  @media all {

    h2,
    h3 {
      font-weight: 600;
    }

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.2;
      color: #020553;
    }

    ul {
      margin-bottom: 1rem;
    }

    @media (min-width:1200px) {
      h2 {
        font-size: 3.4rem;
      }

      h3 {
        font-size: 2.4rem;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 0rem;
    }

    ul {
      margin-top: 0;
    }

    strong {
      font-weight: bolder;
    }

    a {
      color: #1943dc;
      text-decoration: none;
    }

    a:hover {
      color: #020553;
      text-decoration: none;
    }

    ::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-top: var(--bs-gutter-y);
    }

    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }

    h2,
    h3 {
      line-height: 1;
    }

    h2 {
      font-size: 3.4rem;
    }

    @media (min-width:992px) {
      h2 {
        font-size: 3.4rem;
        line-height: 1;
      }
    }

    h3 {
      font-size: 2.4rem;
    }

    @media (min-width:992px) {
      h3 {
        font-size: 2.4rem;
        line-height: 1;
      }
    }

    p {
      font-size: 1.6rem;
    }

    p {
      line-height: 1.5;
    }

    @media (min-width:992px) {
      p {
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }

    ul {
      padding-left: 1.5rem;
    }


    :focus:not(.focus-visible) {
      outline: 0;
    }

    .page__content--col {
      padding-bottom: 16rem;
    }

    .page__content--col h2,
    .page__content--col h3 {
      margin: 4rem 0 2.4rem;
    }

    .page__content--col ul {
      background-color: transparent;
      padding: 0;
      margin-top: 0rem;
      margin-left: 3.2rem;
      margin-bottom: 0;
    }

    .page__content--col ul li {
      margin-bottom: 2rem;
    }

    .page__content--col ul+p {
      margin-top: 0rem;
    }

    @media print {

      a,
      h2,
      h3,
      li,
      p {
        color: #333 !important;
      }

      [class*=col-] {
        float: left;
      }

      .col-12 {
        width: 100% !important;
      }

      .col-12 {
        float: left !important;
      }

      a[href]::after {
        content: none;
      }
    }
  }

  .page__content--row {
    padding-top: 5rem;
  }

  .elementor-element.elementor-element-380d4e6 {
    background-image: url('/img/african-american-businessman-writing-down-his-TM5Y3F7.jpg') !important;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
</style>

<div class="inner">
  <!-- Begin main content -->
  <div class="inner-wrapper">
    <div class="fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="3779" class="elementor elementor-3779" data-elementor-settings="[]">
        <div class="elementor-inner">
          <div class="elementor-section-wrap">
            <section class="elementor-element elementor-element-380d4e6 elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section" data-id="380d4e6" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"classic"&#125;' style="width: 1770px; left: -262.5px;">
              <div class="elementor-background-overlay">
              </div>
              <div class="elementor-container elementor-column-gap-default">
                <div class="elementor-row">
                  <div class="elementor-element elementor-element-50051e6 elementor-column elementor-col-100 elementor-top-column" data-id="50051e6" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
                    <div class="elementor-column-wrap elementor-element-populated">
                      <div class="elementor-widget-wrap smooved" style="perspective: 1000px;">
                        <div class="elementor-element elementor-element-aa6a230 elementor-widget__width-inherit elementor-widget elementor-widget-heading init-smoove smooved" data-id="aa6a230" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":-40,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default" style="transition-duration: 1000ms; opacity: 1;">
                          <div class="elementor-widget-container">
                            <span class="elementor-heading-title elementor-size-default">{app.name} Grants</span>
                          </div>
                        </div>
                        <div class="elementor-element elementor-element-18a1c93 elementor-widget__width-inherit elementor-widget elementor-widget-heading init-smoove smooved" data-id="18a1c93" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":-40,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default" style="transition-duration: 1000ms; opacity: 1;">
                          <div class="elementor-widget-container">
                            <h1 class="elementor-heading-title elementor-size-default">Privacy and Security Policy</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="comment_disable_clearer">
      </div>
    </div>

    <div class="container page__content">
      <div class="row page__content--row">
        <div class="col-12 page__content--col">

          <p>{app.name} (“<strong>{app.name}</strong>” or&nbsp;<strong>“Us”</strong>&nbsp;or “<strong>We</strong>”) is serious about protecting the privacy of your personal information. We have put strict policies into place to ensure that the privacy of your personal information is protected while still enabling you to make use of the website (the “<strong>Site</strong>”) or any mobile application or other products or services provided by {app.name} (collectively with the Site, the “<strong>Services</strong>”). We use information collected about you through the use of the Services or on the Site solely for the purposes set out in this policy. The terms of this policy are also incorporated into the {app.name} <a href="{route('app.terms')}">Terms of Use</a> and <a href="{route('app.privacy')}">Privacy Policy</a>.</p>



          <h2 id="h-what-information-do-we-collect">What information do we collect?</h2>



          <p>We collect personal information from you when you register for our service (directly or indirectly through a partner). “Personal information” generally means information that may be used, alone or in combination with other information, to personally identify a business or individual.</p>



          <p>This information includes, but is not limited to, your name, e-mail address, mailing address, phone number, social security number and bank account information. Personal information does not include publicly available information or information that has been de-identified, aggregated, or in a form that is not capable of being associated with or linked to you.</p>



          <p>We also collect information regarding our transactions and experiences with you, including your payment history. We may also collect information from third-parties’ websites or applications as specified in this policy below. We may also collect information through the use of cookies, as outlined below.</p>



          <p>If you provide, or make available, any personal information about any business personnel, administrators, employees, owners, and other authorized persons you must first obtain their consent to disclose such information to us. You must inform all such other persons how we process personal information and all other terms of this Privacy and Security Policy.</p>



          <h2 id="h-what-do-we-do-with-the-information-we-collect">What do we do with the information we collect?</h2>



          <p>Any of the information we collect from you may be used in one of the following manners:</p>



          <ul>
            <li>To compile, save, use and analyze your information in both a personally identifiable form and an aggregated, non-personally identifiable form;</li>
            <li>To operate, maintain, improve and provide to you our Services, conduct our business and process transactions;</li>
            <li>To verify your identity;</li>
            <li>To register you as a user and identify you when you sign in to your account;</li>
            <li>To conduct background checks and determine your eligibility for our products;</li>
            <li>If you are approved for funding, an account, or other product, to process, service and collect your financing transaction, and otherwise service your account, or other product;</li>
            <li>To personalize your experience and improve customer service (your information helps us to better respond to your individual needs, your service requests, to send marketing communications and send account update notifications);</li>
            <li>To develop and improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you);</li>
            <li>To detect and guard against potential fraud, identity theft, security incidents, or other illegal activity.</li>
          </ul>



          <p>Additional uses are outlined in the California-specific section below titled “What We Do With Your Information.” We will share your personal information with third parties only in the ways that are described in the policy.</p>



          <h2 id="h-security-or-how-do-we-protect-your-information">Security or how do we protect your information?</h2>



          <p>We take security very seriously and have developed a comprehensive set of practices, technologies and policies to help ensure your data is secure. We take your security and privacy seriously and make it a priority. Accordingly, we use advanced security technology and implement a variety of security measures to maintain the safety of your personal information.</p>



          <p>More specifically:</p>



          <ul>
            <li>All of our communications and processing occur through Secure Socket Layers (SSL) technology, which ensures such information is encrypted and secure. Such information is stored in our secure database in an encrypted format, which is only accessible to {app.name} employees and representatives with special access rights. These individuals are required to keep the information confidential.&nbsp;</li>
            <li>We have established a dedicated security team.</li>
            <li>We maintain SOC2 Type 2 compliance from an external certified auditor.</li>
            <li>We maintain PCI DSS SAQ A and SAQ A-EP requirements.</li>
            <li>To help prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.&nbsp;</li>
            <li>We also use the services of leading companies to process all financial transactions.</li>
          </ul>



          <p>While we strive to protect your personal information and use commercially acceptable means to protect your personal information, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, {app.name} cannot ensure or warrant the absolute security of any data or content you transmit to us.&nbsp;</p>



          <p>In the event of a suspected breach of the security of your personally identifiable information, we will, to the extent required by law, notify you so you can take appropriate protective steps and to inform you of the steps that {app.name} is taking with respect to any such suspected breach.</p>



          <h2 id="h-do-we-use-cookies">Do we use cookies?</h2>



          <p>As is true of most platforms, we use tracking technology (i.e., cookies) which help us make our website and services more user-friendly, efficient, and secure. We may use such technology for advertising purposes. Cookies will not harm your computer or mobile device and do not contain viruses.&nbsp;</p>



          <p>If you prefer not to accept cookies, you can opt out in one of the following ways:</p>



          <ul>
            <li>By browser. Most browsers allow you to (1) require that the browser notifies you and lets you choose whether to accept or reject a cookie, (2) disable existing cookies, and/or (3) set your browser to automatically reject cookies. If you wish to reject cookies across all browsers you use, you will need to do this on each browser on each device you use.</li>
            <li>DAA. The Digital Advertising Alliance (DAA) offers tools to opt out of receiving interest-based online advertisements on your browser from parties that participate in the DAA program. You can follow the instructions at www.aboutads.info/choices to place an opt-out cookie on your device. That opt-out cookie will indicate you do not want to receive interest-based ads, and you will need to add it to each browser on each device if you wish to fully opt out.</li>
          </ul>



          <p>Blocking cookies may negatively impact your experience on our website. Additionally, you may still receive ads, though they should not be based on your interests. We are not responsible for the effectiveness of, or compliance with, any third parties’ opt-out options or programs.</p>



          <h2 id="h-do-we-disclose-any-information-to-outside-parties">Do we disclose any information to outside parties?</h2>



          <p>We may provide your information to our trusted business partners who assist us in operating the Site, conducting our business (including partners referring you to us), or servicing your account, so long as those parties agree to keep this information confidential. We also may disclose your information to third parties to whom we may refer you or your inquiry or application for financing or other services, consistent with our notice to you and/or consent from you.&nbsp;In addition, we may disclose our transactions and experiences with you to credit bureaus.&nbsp;</p>



          <p>We do not sell personal information, or otherwise provide personal information to third parties without your prior consent to do so, other than service providers receiving information to perform services for us on our behalf. Below are the following categories of third parties with whom we may share your information:</p>



          <ul>
            <li>Affiliates;</li>
            <li>Our service providers, which may include advertising and marketing companies, social media companies, technology companies, fraud prevention agencies, and credit bureaus;</li>
            <li>Entities with which we have a business relationship, which may include our partners and collection agencies;</li>
            <li>Any other third party with your prior consent to do so.</li>
          </ul>



          <p>In addition, we may also disclose your information:</p>



          <ul>
            <li>As required by law, such as to comply with a subpoena, judicial or administrative order or warrant, and/or any similar legal process;</li>
            <li>When we believe in good faith that disclosure is necessary to (i) protect our rights or the rights of third parties (ii) to protect our property and safety or the property and safety of others, or (iii) investigate fraud or respond to a request from a governmental entity;</li>
            <li>If {app.name} is involved in a merger, acquisition, transfer, auction (including in a bankruptcy proceeding) or sale of all or substantially all of its assets or equity, or of assets related to the divestiture of a particular division or product, in connection with such a transaction;</li>
            <li>In connection with the sale of delinquent debt for collections purposes; or</li>
            <li>With third parties in de-identified or aggregated form for marketing, advertising, research, or other business purposes.&nbsp;</li>
          </ul>



          <h2 id="h-using-third-party-services-and-data-retention">Using Third Party Services and Data Retention</h2>



          <p>You allow us to receive information when you use the Services to log in to a third-party website or application (e.g. Facebook, Twitter or LinkedIn) (the “<strong>Third Party Platform</strong>”). Please note that we retain the information we receive from your personal profile on the Third Party Platform while you are logged-in to the Services and even after you have logged-out from the Services if such retention is reasonably necessary to enforce this policy and our Terms of Use, in our sole discretion.</p>



          <h2 id="h-dmv-information">DMV INFORMATION</h2>



          <p>We may collect information about you from state departments of motor vehicles (“<strong>DMVs</strong>”) for use in the normal course of business to verify the accuracy of information you may submit to us.&nbsp; We may redisclose such DMV information only for a permitted use, including for collection purposes.</p>



          <h2 id="h-partner-policies">Partner Policies</h2>



          <p>From time to time, {app.name} may help you obtain products or services from other banks. The privacy policies for those banks will be provided at the time you contract for those services, as applicable.&nbsp;</p>



          <p>To the extent your use of any {app.name} products or services involves Federal Barc Union Bank, Federal Barc Union Bank’s data protection practices, which are available in its <a href="#" target="_blank" rel="noreferrer noopener"><strong>Privacy Policy</strong></a>, as modified from time to time, are hereby incorporated into this Privacy and Security Policy.&nbsp;</p>



          <h2 id="h-use-by-minors">Use by Minors</h2>



          <p>We do not knowingly collect personal information from anyone under the age of 13. If we learn of such information, we will delete it as quickly as possible. If you believe we may have such information, please contact us.</p>



          <h2 id="h-your-california-privacy-rights">Your California Privacy Rights</h2>



          <p>This section applies to any California residents about whom we have collected personal information from any source, including through your use of our Site, by using our Services, or by communicating with us electronically, in paper correspondence, or in person (collectively, “<strong>you</strong>”). For purposes of this section only, “personal information” means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California consumer/resident or household.&nbsp; Personal information does not include publicly available information or information that has been de-identified, aggregated, or in a form that is not capable of being associated with or linked to you.</p>



          <h3 id="h-a-what-information-we-collect">A. What Information We Collect</h3>



          <p>We may collect the following categories of personal information about you:</p>



          <ul>
            <li>Identifiers, which may include real name and alias; mailing address; unique personal identifier; online identifiers as detailed below; Internet Protocol (“IP”) address; email address; telephone number; account number, name, and password; Social Security number; driver’s license number, passport number, state or other government-issued identification card number; and/or other similar identifiers;</li>
            <li>Commercial information, which may include records of personal property; products or services purchased, obtained, or considered; account balances, payment history, or account activity; bank account information and other information relating to your financial institution; credit application, credit checks, and information from credit reporting agencies; and/or other purchasing or consumer histories or tendencies;</li>
            <li>Information relating to Internet activity or other electronic network activity, which may include cookie identifiers, clear gifs (a.k.a. web beacons/web bugs), browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, clickstream data, device platform, device version, and/or other device characteristics.</li>
            <li>Geolocation data, which may include GPS data; locational information based upon your IP address; cell network data; and/or other similar locational data; and which may be collected from various devices including your mobile device(s);</li>
            <li>Audio, electronic, or visual information, which may include records of calls to or from our customer service centers; and/or video surveillance information;</li>
            <li>Professional or employment-related information, such as your current and previous employers; job title and responsibilities; assets; income; and/or other information related to your work history and/or prospective employment;</li>
            <li>Education information;</li>
            <li>Inferences about you;</li>
            <li>Information not listed above and related to characteristics protected under California or federal law; which may include gender; race and ethnicity; nationality; marital status; military service / veteran status; and/or date of birth;</li>
            <li>Other personal information not listed above and described in California Civil Code § 1798.80(e), which may include signature; physical characteristics or description; insurance policy number; and/or bank account number credit card number, debit card number, and other financial, medical, and/or health insurance information.</li>
          </ul>



          <h3 id="h-b-what-we-do-with-your-information">B. What We Do With Your Information</h3>



          <p>We may collect or use personal information from you for the following purposes:</p>



          <ul>
            <li>Internal analytics</li>
            <li>Assessing third party vendors / service providers</li>
            <li>Audit, compliance, policy, procedures, or regulation</li>
            <li>Billing, payment, and fulfillment</li>
            <li>Customer claims and fraud investigation and prevention</li>
            <li>Customer communications (including, but not limited to, customer support, surveys or questionnaires, and notifications or information about your account and our products and services)</li>
            <li>Customer relationship management</li>
            <li>General business administration</li>
            <li>Marketing our products and services</li>
            <li>Marketing the products and services of others</li>
            <li>Financial reporting and accounting</li>
            <li>Website optimization and maintenance</li>
            <li>Systems and data security</li>
            <li>Maintaining and/or servicing accounts</li>
            <li>Verification purposes</li>
            <li>Evaluating applications for employment</li>
            <li>Identifying, fixing, and troubleshooting bugs and errors</li>
            <li>Understanding how you use our services so we can make improvements</li>
            <li>Facilitate business relationships or comply with contractual obligations</li>
          </ul>



          <p>Additional uses are outlined above in the section titled “What do we do with the information we collect?”</p>



          <h3 id="h-c-sources-of-collected-information">C. Sources of Collected Information</h3>



          <p>We may collect personal information from the following categories of sources:</p>



          <ul>
            <li>Our applicants, prospective customers, existing customers, and their authorized agents, including via our websites, mobile applications, telephone, text message, postal mail, social media, forums, message boards, live chat, chatbot, or other means;</li>
            <li>Our affiliates, which may include our subsidiaries;</li>
            <li>Our service providers, which may include customer relationship management providers, analytics providers, website hosting providers, risk and verification service providers, systems administrators, and communications delivery services;</li>
            <li>Nonaffiliated companies with which we have a business relationship, which may include referral, promotional, and joint marketing partners;</li>
            <li>Other third parties, which may include technology companies, social media platforms, other websites and mobile applications, online advertising partners, state departments of motor vehicles, and other data suppliers;</li>
            <li>Things that other third parties may post publicly about you or otherwise provide to us, which may include review platforms, public forums, and testimonial websites; and</li>
            <li>Employees and job applicants.</li>
          </ul>



          <h3 id="h-d-who-we-share-information-with">D. Who We Share Information With</h3>



          <p>We will provide your information to our trusted business partners who assist us in operating the Site, conducting our business (including partners referring you to us), or servicing your account, so long as those parties agree to keep this information confidential. We also may disclose your information to third parties to whom we may refer you or your inquiry or application for financing or other services, consistent with our notice to you and/or consent from you. In addition, we may disclose our transactions and experiences with you to credit bureaus.&nbsp;</p>



          <p>We do not sell personal information, or otherwise provide personal information to third parties without your prior consent to do so, other than service providers receiving information to perform services for us on our behalf.&nbsp;</p>



          <p id="h-for-more-information-on-who-we-share-information-with-please-see-the-above-section-do-we-disclose-any-information-to-outside-parties">For more information on who we share information with, please see the above section “<em>Do we disclose any information to outside parties?”</em></p>



          <h3 id="h-e-your-privacy-rights">E. Your Privacy Rights</h3>



          <p>If you are a California resident, subject to applicable law, you have the following rights under California law with respect to your personal information:</p>



          <ul>
            <li><strong>Right to Know.</strong>&nbsp; You have the right to request what personal information we collect, use, disclose, and/or sell, as applicable.</li>
            <li><strong>Right to Delete.</strong>&nbsp;You have the right to request the deletion of your personal information that is collected or maintained by us.</li>
            <li><strong>Right to Opt-Out of Sale.</strong> You have the right to opt-out of the sale of your personal information by us, though {app.name} does not currently sell your personal information. For more details on how to exercise this right, please see the following link:&nbsp;<a href="#h-f-notice-of-right-to-opt-out">Do Not Sell My Personal Information.</a></li>
            <li><strong>Right to Non-Discrimination.</strong>&nbsp;You have the right not to receive discriminatory treatment by us for the exercise of the privacy rights described above.</li>
          </ul>



          <p>The above rights are subject to various exclusions and exceptions under applicable laws.&nbsp;</p>



          <p>If you wish to exercise one of the above rights, we will need to verify your identity and authority. Identity verification requires matching pieces of personal information you provide with the information we maintain. In addition, we may require additional steps such as a signed declaration that the requestor is the consumer whose personal information is the subject of the request.&nbsp;</p>



          <p>You may also authorize someone to exercise the above rights on your behalf.&nbsp; To designate an authorized agent, please submit a request through our&nbsp;<a href="{route('app.contact')}" target="_blank" rel="noreferrer noopener">Support form</a>. As may be necessary, we will verify that the agent has proper authorization to exercise the above rights on the consumer’s behalf.&nbsp;&nbsp;</p>



          <p>If you are a California resident and wish to seek to exercise these rights, please reach us in one of the following ways:</p>



          <ul>
            <li>By submitting a webform request:&nbsp; {route('app.contact')}</li>
            <li>By emailing us:&nbsp; {app.email}</li>
          </ul>



          <p>For more information regarding this Privacy Policy, or if you have any questions or concerns, you may contact us in the following ways:</p>



          <ul>
            <li>By submitting a webform request:&nbsp; {route('app.contact')}</li>
            <li>By emailing us:&nbsp; {app.email}</li>
          </ul>



          <h3 id="h-f-notice-of-right-to-opt-out">F. Notice of Right to Opt-Out</h3>



          <p>We do not currently sell your personal information, but you may direct {app.name} not to sell your personal information if that changes in the future.&nbsp; If you wish to opt-out of such sales, please do so in one of the following ways:</p>



          <ul>
            <li>By submitting a webform request:&nbsp; {route('app.contact')}</li>
            <li>By emailing us:&nbsp; {app.email}</li>
          </ul>



          <p>Please consult the other sections of our Privacy Policy for further information on our practices regarding your personal information.</p>



          <h2 id="h-links-to-other-sites">Links to Other Sites</h2>



          <p>The Site may offer links to sites of other companies and associations. We are not responsible for the privacy practices or consent of such third-party sites. These external sites are not subject to this Privacy and Security Policy and we have no control over their content. Please carefully review the policies of these sites before providing any personal information.</p>



          <h2 id="h-governing-law">Governing Law</h2>



          <p>You agree that this policy is governed by the laws of the State of California without regard to conflict of laws hereof.</p>



          <h2 id="h-your-consent">Your Consent</h2>



          <p>By using the Site, you consent to our website’s <a href="{route('app.terms')}">Terms of Use</a>.</p>



          <h2 id="h-changes-to-our-privacy-and-security-policy">Changes to our Privacy and Security Policy</h2>



          <p>We may make changes to this Privacy and Security Policy, and we encourage you to regularly check this page to review any changes we may make. You understand that you will be deemed to have accepted an update if you continue to use the Site and Services after any update takes effect. If any material changes are made to this Privacy and Security Policy, advance notice may be posted on the Site.</p>



          <h2 id="h-effective-date">Effective Date&nbsp;</h2>



          <p>This version of our Privacy and Security Policy is effective as of June 30, 2022.</p>
        </div>
      </div>
    </div>
  </div>
</div>
