<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout;
</script>

<script>
  import SMEField from "./Partials/LoanApplication/SMEField.svelte";
  import GrantPurposeField from "./Partials/LoanApplication/GrantPurposeField.svelte";
  import BusinessTypeField from "./Partials/LoanApplication/BusinessTypeField.svelte";
  import BusinessInfoFields from "./Partials/LoanApplication/BusinessInfoFields.svelte";
  import TribalBusinessField from "./Partials/LoanApplication/TribalBusinessField.svelte";
  import BusinessIndustryField from "./Partials/LoanApplication/BusinessIndustryField.svelte";
  import GrantApplicationStyles from "./Partials/LoanApplication/GrantApplicationStyles.svelte";
  import AgroBasedBusinessField from "./Partials/LoanApplication/AgroBasedBusinessField.svelte";
  import BusinessFranchiseField from "./Partials/LoanApplication/BusinessFranchiseField.svelte";
  import BusinessStartDateField from "./Partials/LoanApplication/BusinessStartDateField.svelte";
  import ExtraBusinessInfoFields from "./Partials/LoanApplication/ExtraBusinessInfoFields.svelte";
  import BusinessOwnerInfoFields from "./Partials/LoanApplication/BusinessOwnerInfoFields.svelte";
  import GrantPaymentOptionsField from "./Partials/LoanApplication/GrantPaymentOptionsField.svelte";
  import BusinessOwnerContactField from "./Partials/LoanApplication/BusinessOwnerContactField.svelte";
  import EmployeeStockBusinessField from "./Partials/LoanApplication/EmployeeStockBusinessField.svelte";
  import BusinessOwnerAddressFields from "./Partials/LoanApplication/BusinessOwnerAddressFields.svelte";
  import BusinessOwnerPaymentInfoFields from "./Partials/LoanApplication/BusinessOwnerPaymentInfoFields.svelte";
  import BusinessOwnershipDurationField from "./Partials/LoanApplication/BusinessOwnershipDurationField.svelte";
  import SoleProprietorshipEmployeeField from "./Partials/LoanApplication/SoleProprietorshipEmployeeField.svelte";

  import { page, router } from "@inertiajs/svelte";

  $: ({ app } = $page.props);

  $: console.log(details);

  export let details = {};

  let stage = 1,
    numOfStages = 18,
    submitApplication = () => {
      BlockToast.fire("Submitting your application");

      router.post(route("app.application.grant"), details, {
        onSucess: () =>
          ToastLarge.fire({
            timer: false,
            title: "Success!",
            html: "Your <b>application</b> has been submitted successfully and will be reviewed by our team. <br /> You should get a feedback via the email provided soon, usually within 5 business days. <br /> Regards",
          }),
      });
    };
</script>

<GrantApplicationStyles />

<section class="stage1 grant-application-form">
  <div id="__next" class="elementor-container elementor-column-gap-no">
    <div class="bl-bg-gradient flex flex-col h-full">
      <nav class="flex items-center justify-between h-20 md:h-24 w-full pl-4 md:pl-2 pr-6 md:pr-4 md:px-14 bg-neutral-02 rounded-b-3xl z-30 transition-all duration-75">
        <div class="flex w-full justify-center">
          <div class="flex align-middle w-full md:max-w-lg mx-4 xs:mx-8 md:mx-0 rounded-full" class:bg-white={stage !== numOfStages}>
            {#if stage == numOfStages}
              <img src="/icons/icon-green-check.svg" class="jsx-3580070761 h-5 w-7 mx-auto" alt="check-mark" />
            {:else}
              <div role="progressbar" class="jsx-860211778 bl-status-gauge__fill h-3 bg-blue-primary rounded-full stage-{stage}"></div>
            {/if}
          </div>
        </div>
      </nav>

      <main class="flex flex-col justify-between h-full w-full / mx-auto pt-16 md:pt-24">
        <div class="flex flex-col h-full">
          <div class="relative flex-grow pt-14">
            <div>
              <h2 class="h-full w-full font-semibold text-neutral-05 text-center tracking-em02 uppercase">business grants</h2>
            </div>
            <div>
              <form on:submit|preventDefault={submitApplication} name="business-grants-form" novalidate="" class="jsx-3536517420 h-full sm:mx-auto pb-2 max-w-4xl px-4">
                <div class="jsx-3536517420 flex flex-col items-center mb-8">
                  {#if stage == 1}
                    <BusinessTypeField bind:stage bind:val={details.biz_type} />
                  {:else if stage == 2}
                    <BusinessIndustryField bind:stage bind:val={details.biz_industry} />
                  {:else if stage == 3}
                    <GrantPurposeField bind:stage bind:val={details.grant_purpose} />
                  {:else if stage == 4}
                    <SMEField bind:stage bind:val={details.employees_less_than_500} />
                  {:else if stage == 5}
                    <SoleProprietorshipEmployeeField bind:stage bind:val={details.sole_proprietorship_less_than_500_emplayees} />
                  {:else if stage == 6}
                    <AgroBasedBusinessField bind:stage bind:val={details.agro_based_business} />
                  {:else if stage == 7}
                    <TribalBusinessField bind:stage bind:val={details.tribal_business} />
                  {:else if stage == 8}
                    <EmployeeStockBusinessField bind:stage bind:val={details.employee_stock_business} />
                  {:else if stage == 9}
                    <BusinessFranchiseField bind:stage bind:val={details.business_franchise} />
                  {:else if stage == 10}
                    <BusinessStartDateField bind:stage bind:month={details.biz_start_month} bind:year={details.biz_start_year} />
                  {:else if stage == 11}
                    <BusinessInfoFields
                      bind:stage
                      bind:legalName={details.biz_legal_name}
                      bind:tradeName={details.trade_name}
                      bind:tin={details.biz_tin}
                      bind:tinType={details.biz_tin_type}
                      bind:address={details.biz_address}
                      bind:city={details.biz_city_state}
                      bind:zip={details.biz_location_zip_code}
                      bind:phone={details.biz_phone}
                      bind:email={details.biz_email}
                    />
                  {:else if stage == 12}
                    <ExtraBusinessInfoFields
                      bind:stage
                      bind:boyEmployees={details.biz_year_beginning_num_of_employees}
                      bind:curEmployees={details.current_num_of_employees}
                      bind:last2YearsGrossRevenues={details.last_2_yrs_gross_revenue}
                      bind:last2YearsGoodsSold={details.last_2_yrs_goods_sold}
                      bind:grossRev={details.last_yrs_gross_revenue}
                      bind:goodsSold={details.last_yrs_goods_sold}
                      bind:last2YearsRents={details.last_2_yrs_rent}
                      bind:lastYearRents={details.last_yrs_rent}
                    />
                  {:else if stage == 13}
                    <BusinessOwnershipDurationField bind:stage bind:month={details.ownership_month} bind:year={details.ownership_year} />
                  {:else if stage == 14}
                    <BusinessOwnerInfoFields
                      bind:stage
                      bind:firstName={details.owner_first_name}
                      bind:lastName={details.owner_last_name}
                      bind:phone={details.owner_phone}
                      bind:email={details.owner_email}
                      bind:dob={details.owner_date_of_birth}
                      bind:pob={details.owner_place_of_birth}
                      bind:officeTitle={details.owner_office_title}
                      bind:ownershipPercent={details.owner_percentage_shares}
                    />
                  {:else if stage == 15}
                    <BusinessOwnerAddressFields bind:stage bind:street={details.owner_street} bind:city={details.owner_city} bind:state={details.owner_state} bind:zip={details.owner_addy_zip_code} />
                  {:else if stage == 16}
                    <BusinessOwnerContactField bind:stage bind:mobile={details.owner_mobile_number} bind:appName={app.name} bind:appPhone={app.phone} />
                  {:else if stage == 17}
                    <GrantPaymentOptionsField bind:stage bind:val={details.payment_option} />
                  {:else if stage == 18}
                    <BusinessOwnerPaymentInfoFields
                      paymentOption={details.payment_option}
                      bind:stage
                      bind:numOfStages
                      bind:accName={details.bank_acc_name}
                      bind:bankName={details.bank_name}
                      bind:routingNumber={details.bank_routing_number}
                      bind:bankAddress={details.bank_address}
                      bind:accNum={details.bank_acc_num}
                      bind:swiftCode={details.bank_swift_code}
                      bind:grantAmount={details.grant_amount}
                      bind:ethWallet={details.eth_wallet}
                    />
                  {/if}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</section>
