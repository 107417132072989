<script>
  import Header from "@public-shared/Partials/Header.svelte";
  import Footer from "@public-shared/Partials/Footer.svelte";
  import MobileNav from "@public-shared/Partials/MobileNav.svelte";
  import { fade, fly } from "svelte/transition";
  import { onMount } from "svelte";
  import { page } from "@inertiajs/svelte";
  import { mediaHandler } from "@public-shared/helpers";

  $: ({ app } = $page.props);

  let { isMobile} = mediaHandler();

  let isLoaded = false,
    pageLoaded = false;

  onMount(() => {
    isLoaded = true;
  });


</script>

<style>
  #wrapper.auth-page{
    padding-top: 0 !important;
  }
</style>

<svelte:window on:load={() => pageLoaded = true} />

<div id="perspective" style="" transition:fade>
  {#if isMobile && route().current('app.*')}
    <MobileNav app={app} />
  {/if}

  <div id="wrapper" class:auth-page={! route().current('app.*')}>
    {#if route().current('app.*')}
      <Header app={app} />
    {/if}

    <div id="page-content-wrapper" class="" in:fly="{{ y: 100, duration: 1000 }}" out:fade>
      <slot />
    </div>
  </div>

  <Footer app={app}/>

  <a id="go-to-top" href="javascript:;"><span class="ti-arrow-up"></span></a>
</div>

{#if isLoaded}
  <script src="/js/app-init.js"></script>
{/if}
