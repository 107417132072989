<script>
import { onMount } from 'svelte';



onMount(() => {
  const attemptRemoveMobileMenu = setInterval(() => {
      try {
        jQuery('#btn-close-mobile-menu').trigger('click');
        clearInterval(attemptRemoveMobileMenu);
      } catch (error) {
        console.log(error);
      }
    }, 300);
})
</script>
