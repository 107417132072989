<script context="module">
  import PublicPagesLayout from "@public-shared/Layout.svelte";
  export const layout = PublicPagesLayout
</script>

<script>
  import InputField from '@public-assets/js/Components/InputField.svelte';
  import { page, router } from '@inertiajs/svelte';

$: ({ app } = $page.props);

export let data;

$: grantApplications = data.filter(i => i.user_full_name.toLowerCase().includes(searchQ.toLowerCase()))

let currentDetails = {}, grantToApprove = {}, details = {}, searchQ = "",


approveApplication = req => {
  router.post(route('superadmins.grant.approve', req), details ,{
    onSuccess: () => {grantToApprove = {}; details = {}}
  })
},

notifyFailure = req => {
  router.post(route('superadmins.grant.failed', req), details ,{
    // onSuccess: () =>
  })
};
</script>

<style lang="scss">
  $baseColor: #1943dc;
  $borderRadius: 10px;
  $imageBig: 100px;
  $imageSmall: 60px;
  $padding: 10px;

  .text-capitalize{
    text-transform: capitalize !important;
  }

  .inner {
    background-color: lighten($baseColor, 30%);
    * { box-sizing: border-box; }
  }

  .header {
    background-color: darken($baseColor, 5%);
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
  }

  .table-users {
    border: 1px solid darken($baseColor, 5%);
    border-radius: $borderRadius;
    box-shadow: 3px 3px 0 rgba(0,0,0,0.1);
    max-width: calc(100% - 2em);
    margin: 1em auto;
    overflow: hidden;
    width: 800px;
  }

  table {
    width: 100%;

    td, th {
        color: darken($baseColor, 10%);
        padding: $padding;
    }

    td {
        text-align: center;
        vertical-align: middle;

        &:last-child {
          font-size: 0.95em;
          line-height: 1.4;
          text-align: left;
        }
    }

    th {
        background-color: lighten($baseColor, 50%);
        text-align: center;
        font-weight: bold;
    }

    tr {
        &:nth-child(2n) { background-color: white; }
        &:nth-child(2n+1) { background-color: lighten($baseColor, 55%) }
    }
  }

  @media screen and (max-width: 700px) {
    table, tr, td { display: block; }

    td {
        &:first-child {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: $imageBig;
        }

        &:not(:first-child) {
          clear: both;
          margin-left: $imageBig;
          padding: 4px 20px 4px 90px;
          position: relative;
          text-align: left;

          &:before {
              color: lighten($baseColor, 30%);
              content: '';
              display: block;
              left: 0;
              position: absolute;
          }
        }

        &:nth-child(2):before { content: 'Name:'; }
        &:nth-child(3):before { content: 'Email:'; }
        &:nth-child(4):before { content: 'Phone:'; }
        &:nth-child(5):before { content: 'Comments:'; }
    }

    tr {
        padding: $padding 0;
        position: relative;
        &:first-child { display: none; }
    }
  }

  @media screen and (max-width: 500px) {
    .header {
        background-color: transparent;
        color: lighten($baseColor, 60%);
        font-size: 2em;
        font-weight: 700;
        padding: 0;
        text-shadow: 2px 2px 0 rgba(0,0,0,0.1);
    }

    td {
        &:first-child {
          background-color: lighten($baseColor, 45%);
          border-bottom: 1px solid lighten($baseColor, 30%);
          border-radius: $borderRadius $borderRadius 0 0;
          position: relative;
          top: 0;
          transform: translateY(0);
          width: 100%;
        }

        &:not(:first-child) {
          margin: 0;
          padding: 5px 1em;
          width: 100%;

          &:before {
              font-size: .8em;
              padding-top: 0.3em;
              position: relative;
          }
        }

        &:last-child  { padding-bottom: 1rem !important; }
    }

    tr {
        background-color: white !important;
        border: 1px solid lighten($baseColor, 20%);
        border-radius: $borderRadius;
        box-shadow: 2px 2px 0 rgba(0,0,0,0.1);
        margin: 0.5rem 0;
        padding: 0;
    }

    .table-users {
        border: none;
        box-shadow: none;
        overflow: visible;
    }
  }

  tbody:hover td { /* #maintrick */
    transition: all 300ms ease-in-out;
    text-shadow: 0 0 3px #aaa;
    color: transparent;
  }

  tbody:hover tr:hover td { /* #maintrick */
    text-shadow: 0 1px 0 #fff;
    color: #444;
  }

  .modal{
    background-color: transparentize(#000, 0.4);
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    left: 0;
    top: 0;

    .modal-content{
      background-color: #fff;
      min-width: 600px;
      max-width: 40vw;

      @media screen and (max-width: 990px) {
        max-width: 85vw;
      }
    }

    .modal-heading{
      text-transform: uppercase;
      padding: 20px 0 0 0;
      position: relative;
      text-align: center;
      font-size: 24px;
      width: 100%;
    }

    .modal-body{
      background-color: #fff;
      position: relative;
      max-height: 800px;
      margin: 0 auto;
      overflow: auto;
      padding: 30px;
      width: 100%;

      &.tac{
        text-align: center;
      }
    }

    .close-button{
      transition: text-shadow 200ms ease-out, color 200ms ease-in;
      text-shadow: 0 0 3px #fff;
      text-transform: uppercase;
      position: absolute;
      font-family: arial;
      font-weight: bold;
      color: #808291;
      font-size: 14px;
      cursor: pointer;
      padding: 10px;
      right: 2%;
      top: 1%;

      &:hover{
        text-shadow: 0 0 3px #777;
        color: #fff;
      }
    }
  }

  .btn-design{
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    &:disabled{
      cursor: not-allowed;
    }

    &.btn-md{padding: 7px 15px; font-size: 12px;}
    &.btn-sm{padding: 2px 5px; font-size: 12px;}

    &.color2 {background-color: #008CBA;} /* Blue */
    &.color3 {background-color: #f44336;} /* Red */
    &.color4 {background-color: #e7e7e7; color: black;} /* Gray */
    // &.color5 {background-color: #555555;}
  }
</style>

<div class="inner">
  <div class="inner-wrapper">
    <div class="sidebar-content fullwidth">
      <div data-elementor-type="wp-page" data-elementor-id="4309" class="elementor elementor-4309" data-elementor-settings="[]">

        <div class="table-users">
          <div class="header">Grant Application Requests</div>
          <div class="search">
            <InputField style="three" placeholder="Search" bind:val="{searchQ}" fullWidth={true}/>
          </div>

          <table cellspacing="0">
            <thead>

              <tr>
                <th>Actions</th>
                <th>Name</th>
                <th>Phone</th>
                <th width="230">Grant Reason</th>
              </tr>
            </thead>
            <tbody>
              {#each grantApplications as req, idx}
                <tr>
                  <td>
                    <button class="btn-design btn-sm" on:click="{ () => currentDetails = req.details }">Details</button>
                    {#if ! req.is_approved}
                      <button class="btn-design btn-sm color2" on:click="{() => grantToApprove = req}">Approve</button>
                    {/if}
                    {#if req.is_approved}
                      <button class="btn-design btn-sm color3" on:click="{() => notifyFailure(req)}" >
                        Payment Failed
                        {#if req.payment_failed}
                           <span>AGAIN</span>
                        {/if}
                      </button>
                    {/if}
                  </td>
                  <td>{req.user_full_name}</td>
                  <td>{req.user_phone}</td>
                  <td>{req.grant_reason}</td>
                </tr>
              {/each}

            </tbody>
          </table>

          {#if Object.entries(currentDetails).length}
            <div class="modal" on:click|self|stopPropagation="{() => currentDetails = {}}" on:keypress|self|stopPropagation="{() => currentDetails = {}}" role="button" tabindex="">
              <div class="modal-content">
                <div class="modal-heading">
                  <div class="close-button" on:click="{() => currentDetails = {}}" on:keypress="{() => currentDetails = {}}" role="button" tabindex="">x</div>
                  {currentDetails.owner_first_name ?? currentDetails.owner_full_name}'s Details
                </div>
                <div class="modal-body">
                  <table cellspacing="0">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {#each Object.entries(currentDetails) as [field, val], idx}
                        <tr>
                          <td class="text-capitalize">{field.replaceAll('_', ' ')}</td>
                          <td>{val}</td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          {/if}

          {#if Object.entries(grantToApprove).length}
            <div class="modal" on:click|self|stopPropagation="{() => grantToApprove = {}}" on:keypress="{() => grantToApprove = {}}" role="button" tabindex="">
              <div class="modal-content">
                <div class="modal-heading">
                  <div class="close-button" on:click="{ () => grantToApprove = {} }" on:keypress="{ () => grantToApprove = {} }" role="button" tabindex="">x</div>
                  Approve {currentDetails.owner_first_name ?? currentDetails.owner_full_name}'s Grant
                </div>
                <div class="modal-body tac">
                  <InputField style="two" placeholder="Enter Approved Amount" bind:val="{details.approved_amount}"/>
                  <button class="btn-design btn-md color4" on:click="{() => approveApplication(grantToApprove)}" disabled={!details.approved_amount}>Approve</button>
                </div>
              </div>
            </div>
          {/if}
       </div>
      </div>
    </div>
  </div>
</div>
