<script>
  export let year = undefined, month = undefined, stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Current Business Ownership Since?</h1>

<div class="input-container">
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="ownershipStartMonth">Month</label>
      <div>
        <div id="ownershipStartMonth" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21 ">

          <select bind:value="{month}" id="ownershipStartMonth-options-menu" class="jsx-329458114 flex items-center / w-full h-18 px-4 py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 cursor-default text-neutral-04 bg-neutral-02 border-2 border-gray-secondary" required>
            <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
              <span class="jsx-329458114 text-left truncate truncate-width">Select One</span>
            </option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">January</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">February</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">March</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">April</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">May</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">June</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">July</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">August</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">September</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">October</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">November</option>
            <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">December</option>
          </select>

        </div>
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="startYear">Year</label>
      <div>
        <div id="startYear" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21 ">
          <select bind:value="{year}" id="startYear-options-menu" class="jsx-329458114 flex items-center / w-full h-18 px-4 py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 cursor-default text-neutral-04 bg-neutral-02 border-2 border-gray-secondary" required>
            <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
              <span class="jsx-329458114 text-left truncate truncate-width">Select Year</span>
            </option>
            {#each Array(50) as _, i}
              <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">{2022 - i}</option>
            {/each}
          </select>
        </div>
      </div>
    </div>
  </div>
  <button on:click={() => stage = 12 } type="button" class="jsx-373440444 btn btn-continue  ">Back</button>
  <button disabled={! year || ! month} on:click={() => stage = 14 } type="button" class="jsx-373440444 btn btn-continue  ">Continue</button>
</div>
