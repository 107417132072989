<script>
  export let stage,
    numOfStages,
    paymentOption = 'bank',
    accName = undefined,
    bankName = undefined,
    routingNumber = undefined,
    bankAddress = undefined,
    accNum = undefined,
    swiftCode = undefined,
    ethWallet = undefined,
    grantAmount = undefined;
</script>

<div class="relative flex">
  <div class="flex">
    <h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Where to send funds?</h1>
  </div>
</div>
<div class="input-container fluid">

  {#if paymentOption == 'bank'}
    <div class="xs:flex xs:space-x-3">
      <div class="w-full">
        <label class="form-input-label" for="accName">Account Name</label>
        <div>
          <input bind:value="{accName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="accName" name="accName" type="text" placeholder="Account Name">
        </div>
      </div>
      <div class="w-full">
        <label class="form-input-label" for="bankName">Bank Name</label>
        <div>
          <input bind:value="{bankName}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="bankName" name="bankName" type="text" placeholder="Bank Name">
        </div>
      </div>
      <div class="w-full">
        <label class="form-input-label" for="routingNumber">Routing Number</label>
        <div>
          <input bind:value="{routingNumber}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="routingNumber" inputmode="numeric" name="routingNumber" type="text" placeholder="Routing Number">
        </div>
      </div>
    </div>

    <div class="w-full">
      <label class="form-input-label" for="bankAddress">Bank Address</label>
      <div>
        <input bind:value="{bankAddress}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="bankAddress" name="bankAddress" type="text" placeholder="Bank Address">
      </div>
    </div>

    <div class="xs:flex xs:space-x-3">
      <div class="w-full">
        <label class="form-input-label" for="accNum">Account Number (IBAN)</label>
        <div>
          <input bind:value="{accNum}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="accNum" inputmode="numeric" name="accNum" type="text" placeholder="Account Number">
        </div>
      </div>
      <div class="w-full">
        <label class="form-input-label" for="swiftCode">Bank Swift Code</label>
        <div>
          <input bind:value="{swiftCode}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="swiftCode" inputmode="numeric" name="swiftCode" type="text" placeholder="Bank Swift Code">
        </div>
      </div>
    </div>
  {:else}
    <div class="w-full">
      <label class="form-input-label" for="ethWallet">Ethereum Wallet Address (TRC20)</label>
      <div>
        <input bind:value="{ethWallet}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="ethWallet" name="ethWallet" type="text" placeholder="Ethereum Wallet Address (TRC20)">
      </div>
    </div>
  {/if}


  <div class="w-full">
    <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
    <label class="form-input-label" for="grantAmountrequired">Grant Amount Required</label>
    <div class="w-full my-auto">
      <input bind:value="{grantAmount}" inputmode="numeric" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="grantAmountrequired" name="grantAmountrequired" autocomplete="grantAmountrequired" autocorrect="off" placeholder="Grant Amount required"/>
    </div>
  </div>

  <button on:click={() => stage = numOfStages - 2} type="submit" class="jsx-373440444 btn btn-continue">Back</button>
  <button disabled={! grantAmount} on:click={() => stage = numOfStages} type="submit" class="jsx-373440444 btn btn-continue">Submit Application</button>
</div>
