<script>
  export let stage,
    boyEmployees = undefined,
    curEmployees = undefined,
    last2YearsGrossRevenues = undefined,
    last2YearsGoodsSold = undefined,
    grossRev = undefined,
    goodsSold = undefined,
    last2YearsRents = undefined,
    lastYearRents = undefined;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Additional Business Information</h1>

<div class="input-container fluid">
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label visible" for="boyNumEmployees">Number of Employess <span class="text-danger">(As of January 31 {new Date().getFullYear()})</span></label>
      <div class="w-full my-auto">
        <input bind:value="{boyEmployees}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="boyNumEmployees" name="boyNumEmployees" placeholder="Number of Employess" type="number">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label visible" for="currNumEmployees">&nbsp;</label>
      <div class="w-full my-auto">
        <input bind:value="{curEmployees}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="currNumEmployees" name="currNumEmployees" placeholder="Current Number of Employess" type="number">
      </div>
    </div>
  </div>

  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
      <label class="form-input-label" for="last2YearsGrossRevenues">Actual {new Date().getFullYear() - 2 } Gross Revenues</label>
      <div class="w-full my-auto">
        <input bind:value="{last2YearsGrossRevenues}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="last2YearsGrossRevenues" name="last2YearsGrossRevenues" placeholder="Actual {new Date().getFullYear() - 2 } Gross Revenues" type="number">
      </div>
    </div>
    <div class="w-full">
      <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
      <label class="form-input-label" for="last2YearsGoodsSold">Actual {new Date().getFullYear() - 2 } Cost of Goods Sold</label>
      <div class="w-full my-auto">
        <input bind:value="{last2YearsGoodsSold}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="last2YearsGoodsSold" name="last2YearsGoodsSold" placeholder="Actual {new Date().getFullYear() - 2 } Cost of Goods Sold" type="number">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
      <label class="form-input-label" for="lastYearGrossRevenues">Actual {new Date().getFullYear() - 1 } Gross Revenues</label>
      <div class="w-full my-auto">
        <input bind:value="{grossRev}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="lastYearGrossRevenues" name="lastYearGrossRevenues" placeholder="Actual {new Date().getFullYear() - 1 } Gross Revenues" type="number">
      </div>
    </div>
    <div class="w-full">
      <label class="form-input-label" for="lastYearGoodsSold">Actual {new Date().getFullYear() - 1 } Cost of Goods Sold</label>
      <div class="w-full my-auto">
        <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
        <input bind:value="{goodsSold}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="lastYearGoodsSold" name="lastYearGoodsSold" placeholder="Actual {new Date().getFullYear() -1 } Cost of Goods Sold" type="number">
      </div>
    </div>
  </div>
  <div class="xs:flex xs:space-x-3">
    <div class="w-full">
      <label class="form-input-label" for="last2YearsRents">Actual {new Date().getFullYear() - 2 } Rental Costs</label>
      <div class="w-full my-auto">
        <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
        <input bind:value="{last2YearsRents}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="last2YearsRents" name="last2YearsRents" placeholder="Actual {new Date().getFullYear() - 2 } Rental Costs" type="number">
      </div>
    </div>
    <div class="w-full">
      <span class="absolute ml-3 text-lg text-gray-500 dollar-sign-format">$</span>
      <label class="form-input-label" for="lastYearRents">Actual {new Date().getFullYear() - 1 } Rental Costs</label>
      <div class="w-full my-auto">
        <input bind:value="{lastYearRents}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" autocorrect="off" id="lastYearRents" name="lastYearRents" placeholder="Actual {new Date().getFullYear() - 1 } Rental Costs" type="number">
      </div>
    </div>
  </div>
  <button on:click={() => stage = 11 } type="button" class="jsx-373440444 btn btn-continue ">Back</button>
  <button disabled={! lastYearRents || ! boyEmployees} on:click={() => stage = 13 } type="button" class="jsx-373440444 btn btn-continue ">Continue</button>
</div>
