<script>
  export let mobile = undefined, appName, appPhone, stage;
</script>

<div class="relative flex">
  <div class="flex">
    <h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">What's the best way to reach you?</h1>
  </div>
</div>

<div class="input-container">
  <label class="form-input-label" for="mobileNumber">Mobile Number</label>
  <div>
    <input bind:value="{mobile}" class="w-full h-18 my-1 mb-2 px-4 xs:text-fs21 text-neutral-07 bg-neutral-02 focus:outline-none focus:ring-2 focus:ring-green-primary rounded-2xl text-center border border-gray-secondary" id="mobileNumber" inputmode="numeric" name="mobileNumber" type="text" pattern="[0-9]*" placeholder="(111) 222-3333">
  </div>
  <button on:click={() => stage = 15 } type="button" class="jsx-373440444 btn btn-continue">Back</button>
  <button disabled={! mobile} on:click={() => stage = 17 } type="button" class="jsx-373440444 btn btn-continue">Continue</button>
</div>

<div>
  <p class="mt-6 mb-2 text-sm text-neutral-650 leading-relaxed">By clicking on the "Continue" button above, I consent, acknowledge, and agree to the following:​</p>
  <p class="mt-6 mb-2 text-sm text-neutral-650 leading-relaxed"></p>
  <ul class="ml-4 text-sm text-neutral-650 leading-relaxed list-disc">
    <li>Our&nbsp;<a class="text-navy-primary / hover:underline" href="#" rel="noopener noreferrer" target="_blank">Terms of Use</a> and&nbsp;<a class="text-navy-primary / hover:underline" href="#" rel="noopener noreferrer" target="_blank">Privacy Policy</a>&nbsp;and to receive important notices, and other&nbsp;communications&nbsp;electronically.</li>
    <li>You are providing express written consent to share your information with up to five (5) network&nbsp;partners, and for {appName}, parties calling on behalf of {appName}, network partners, or an&nbsp;authorized third party on their behalf to call you (including through automated means; e.g. autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS - charges may apply) and/or&nbsp;email, even if your telephone number is currently listed on any internal, corporate, state, federal or national Do-Not-Call (DNC) list.​</li>
    <li>Consent is not required as a condition to utilize {appName} services, and you may choose to be contacted by an individual customer care representative by calling <a class="text-navy-primary / hover:underline" href="tel:{appPhone}" rel="noopener noreferrer" target="_blank">{appPhone}</a></li>
    <li>{appName}’s network partners&nbsp;will contact you directly to discuss your grant options as well as to obtain additional financial information required.</li>
    <li>I am directing {appName} to share my information to the providers in its network for the purpose of providing me with information about their financial services and products.​</li>
  </ul>
</div>
