<section class="elementor-element elementor-element-46f696e elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="46f696e" data-element_type="section" data-settings='&#123;"stretch_section":"section-stretched","background_background":"gradient"&#125;'>
  <div class="elementor-container elementor-column-gap-default">
    <div class="elementor-row">
      <div class="elementor-element elementor-element-121c664 elementor-column elementor-col-66 elementor-top-column" data-id="121c664" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-dbce393 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="dbce393" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">Looking for a business grant?</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-1ffb3c9 elementor-widget__width-inherit elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading" data-id="1ffb3c9" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <div class="elementor-heading-title elementor-size-default">
                  Request for a review today
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-element elementor-element-24459af elementor-column elementor-col-33 elementor-top-column" data-id="24459af" data-element_type="column" data-settings='&#123;"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;'>
        <div class="elementor-column-wrap elementor-element-populated">
          <div class="elementor-widget-wrap">
            <div class="elementor-element elementor-element-421022c elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="421022c" data-element_type="widget" data-settings='&#123;"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":&#123;"unit":"px","size":-90,"sizes":[]&#125;,"avante_ext_smoove_translatey":&#123;"unit":"px","size":40,"sizes":[]&#125;,"avante_ext_smoove_translatez":&#123;"unit":"px","size":-140,"sizes":[]&#125;,"avante_ext_is_scrollme":"false","avante_ext_smoove_duration":400,"avante_ext_smoove_scalex":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_scaley":&#123;"unit":"px","size":1,"sizes":[]&#125;,"avante_ext_smoove_rotatey":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_rotatez":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_translatex":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewx":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_skewy":&#123;"unit":"px","size":0,"sizes":[]&#125;,"avante_ext_smoove_perspective":&#123;"unit":"px","size":1000,"sizes":[]&#125;,"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"&#125;' data-widget_type="button.default">
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <a href="{route('app.application.start')}" class="elementor-button-link elementor-button elementor-size-md" role="button">
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-icon elementor-align-icon-right">
                        <i aria-hidden="true" class="fas fa-long-arrow-alt-right"></i></span>
                      <span class="elementor-button-text">Get Started</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
