<script>
  export let val = undefined, stage;
</script>

<h1 class="jsx-744535090 mb-6 text-neutral-07 text-center font-serif mx-10 md:mx-0 text-3xl md:text-5xl">Is your business a franchise?</h1>
<div class="input-container">
  <div>
    <label class="form-input-label" for="businessName">Franchise Status</label>
    <div>
      <div id="franchiseStatus" class="jsx-329458114 relative / w-full mt-px mb-2 xs:text-fs21 ">
        <select bind:value="{val}" id="franchiseStatus-options-menu" class="jsx-329458114 flex items-center / w-full h-18 px-4 py-2 rounded-2xl focus:outline-none focus:ring-2 focus:ring-green-primary transition-transform duration-75 text-neutral-04 bg-neutral-02 border-2 border-gray-secondary" required>
          <option class="jsx-329458114 block min-h-9 w-full bg-white" value={undefined}>
            <span class="jsx-329458114 text-left truncate truncate-width">Select One</span>
          </option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">Yes</option>
          <option class="jsx-329458114 block min-h-9 w-full bg-white hover:bg-green-secondary">No</option>
        </select>
      </div>
    </div>
  </div>
  <button on:click={() => stage = 8 }  type="button" class="jsx-373440444 btn btn-continue">Back</button>
  <button disabled={!val} on:click={() => stage = 10 }  type="button" class="jsx-373440444 btn btn-continue">Continue</button>
</div>
